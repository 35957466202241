

:root {
  --naranja: #e99e3c;  /* Color principal */
  --gris-oscuro: #525151;  /* Color secundario */
  --gris-claro: #d9d9d9;  /* Color de acento */
  --gris-medio:#7f7f7f;
  --gris-oscuro-acacias:#545454;
  --verde-sauces:#95b733;
  --verde-oscuro-piamonte:#27703b;
  --verde-claro-piamonte:#95b733;
  --verde-oscuro-santaclara:#27703b;
  --verde-claro-santaclara:#95b733;
  --azul-oscuro-acacias:#072b5f;
  --azul-oscuro-alameda:#072b5f;
  --azul-claro-alameda:#3b82bd;
  --naranja-claro-alameda:#e99f3c;
  --font-cuerpo: 'Futura', Arial, sans-serif;
  --font-titulos: 'Mulish', sans-serif;
  --letra-h1:3.5rem;
  --letra-h2:2.5rem;
  --letra-h3:1.5rem;
  --letra-p:1.3rem;
  --letra-pp:0.9rem;
  --letra-boton:2rem;


}


html, body {
  width: 100%;
  overflow-x: hidden; /* Ocultar el scroll horizontal */
  background-color: white;
  
}

* {
  box-sizing: border-box; /* Para asegurar que padding y borders no causen desbordamiento */
}

/* Navbar contenedor */
.navbar {
  background-color: white;
  margin: 0;
  display: flex;  /* Alinea los elementos en fila (row) */
  justify-content: space-between; /* Distribuye los elementos horizontalmente */
  align-items:flex-end;
  text-align: center;
  width: 100%;
  padding: 3rem 4rem 0 4rem;
  

  
}

.logo {
  width: 100%; 
  
  
}

/*
.navbar.active {
  background-color: transparent;
  z-index: 100;
}*/

/* Establecemos flexbox en el ul para alinear en fila */
.navbar-pc .navbar-menu {
  list-style-type: none;
  margin: 0;
  /*position: absolute; 
  display: block;*/
  /*display: flex;  
  align-items: center; 
  justify-content: space-around;
  text-align: center;*/
  display: flex; /* Flexbox para menú horizontal */
  justify-content: center;
  align-items: center;
  /*flex-direction: row;*/
  gap: 2rem; /* Espaciado entre elementos */
  position: relative; /* Necesario para submenús */
  width: 55rem;
  padding: 0; 

  
  

  
}
.navbar-pc .navbar-menu ul {
  /* removed some rules that were causing bad positioning */
  position: absolute; /* instead of relative - stops pushing other menu items around */
  display: none;
  font-size: var(--letra-h3);
  font-family: var(--font-cuerpo);
  background-color: white;
  color: var(--gris-oscuro);
  list-style: none;
  padding: 0;
  margin: 0;
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.2); 
  top:100%;
  
  
}
.navbar-pc .navbar-menu ul ul {
  /* removed some rules that were causing bad positioning */
  position: absolute; /* instead of relative - stops pushing other menu items around */
  display: none;
  font-family: var(--font-cuerpo);
  background-color: white;
  width: auto;
  left: 100%;
  top:0;
  
}
.navbar-pc .navbar-menu ul ul li{
  width: 8rem;
  
}
.navbar-pc .navbar-menu ul ul li a{
  color: var(--gris-oscuro);
  width: 8rem;
  margin:0;
  padding: 0;
  text-align: center;
}

/* Cada item del navbar */
.navbar-menu li {
  /*margin: 0 0.0625rem;*/ /* Espaciado horizontal entre los elementos */
  display: flex;  
  align-items: center; 
  text-align: left;
  padding: 0.5rem;
  max-width: 13rem;
  position: relative;

   
}



/* Menu 1 (navbar ppal) */
.navbar-pc .navbar-menu li a {
  color: var(--gris-oscuro);
  text-decoration: none;
  display: block;
  position: relative; 
  height: 2.9rem;
  font-size: var(--letra-p);
  font-weight: bold;
 

}


.navbar-pc > ul > li > a:hover::after {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 0.125rem; /* Grosor de la línea */
  background-color: var(--naranja); 
  border-radius: 0.3125rem;
  transition: width 0.3s ease; /* Animación suave */
  max-width: 10rem;
  
}

.navbar-pc li.active > ul {
  display: block; /* Muestra el submenú cuando está activo */
}

/* Al cargar la página, el enlace de "Home" tiene la línea por defecto */
.navbar-pc li a.active::after {
  width: 100%; /* Muestra la línea morada completamente debajo de "Home" */
}
.navbar-pc li a.active:not([href="#"])::after {
  width: 100%; /* Solo activa la línea si no es # */
}

/* Muestra la lista cuando se pasa el ratón por encima */

.navbar-pc li:hover > ul {
  display: block; 
}

/*Menu 2 (Ciudades)*/
.navbar-pc .navbar-menu ul li {
  padding: 0.5rem;
  cursor: pointer;
  font-size: var(--letra-p);
  width: 8rem;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid gray;
}
.navbar-pc .navbar-menu .servicioCliente li{
  width: 100%;
  padding: 0.1rem;
  height: auto;
  font-size: var(--letra-p);
}


/*Menu 3 (Proyectos)*/
.navbar-pc .navbar-menu ul li a{
  font-size: 1.2rem;
  text-align: center;
  align-items: flex-start;
  justify-content: center;
  font-weight: normal;
  display: flex;
  height: 2rem;
  

}


.navbar-pc .navbar-menu ul li:hover {
  background-color: var(--naranja);

}


.navbar-pc .navbar-menu ul ul li:hover {
  background-color: var(--naranja);
 
}

.btnNaranjaNavbar{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  
  width: auto;  
  height: 3rem ; 
  color: var(--gris-oscuro);
  background: var(--naranja);
  border-radius: 2rem;
  margin:0 0 1rem 0;
  text-align: center;
  cursor: pointer; 
  padding: 1.5rem 1rem 1.5rem 1rem;
  

}
.btnNaranjaNavbar a{
  font-size: var(--letra-h3) !important;
  font-weight: bold;
  text-align: center;
  
}
.btnNaranjaFootbar{
  display: inline-block; 
  width: auto;  
  height: auto ; 
  color: var(--gris-oscuro);
  background: var(--naranja);
  border-radius: 2rem;
  margin:0 0 1rem 0;
  text-align: center;
  cursor: pointer; 
  padding: 0 1rem 0 1rem;
  
  
}
.btnNaranjaFootbar a{
  font-size: var(--letra-boton) !important;
  font-weight: bold;
  
}

/*INICIO*/

.inicio{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: space-between; 
  text-align: center;
  background-color: white;
  width: 100%;

}

.BannerHome{
  width: 100%; 
  margin: 2rem 0rem 0 0rem;
  padding:0;
  
}

.aliados{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-between; 
  padding: 0 4rem 0 4rem;
  
  margin: 2rem 0 2rem 0;
  height: 40%;
  

}


.aliados{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: space-between; 
}
.aliadosInfo{
  display: flex; 
  flex-direction: row;
  align-items: flex-start; 
  justify-content: space-between; 
  margin:2rem 4cm 0 0;
  height: 100%;
  
  
}
.aliadosInfo > h2 {
  font-size: var(--letra-h2);
  color:var(--gris-oscuro);
  font-family: var(--font-titulos);
  font-weight: 900;
  text-align: left;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  width: 50%;
  margin: 0 1rem 0 3rem;
}
.cuadro-gris-btn{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: space-between; 
  background-color: var(--gris-oscuro);
  width: 32%;
  border-radius: 0.5rem;
  margin: 0 1rem 0 0;
}
.cuadro-gris-btn h3{
  color:var(--gris-claro);
  font-size:var(--letra-h3);
  font-family: var(--font-cuerpo);
  padding: 0.5rem;

}
.btnGrisClaro {
  background-color: var(--gris-claro);
  border-radius: 2rem;
  width: 50%;
  padding: 0.5rem 0 0.5rem 0;
  margin: 0;
  
}
.btnGrisClaro a{
  color: var(--gris-oscuro) !important;
  font-size: var(--letra-p);
  font-weight: bold;
  -webkit-text-stroke: 0.3px var(--gris-oscuro); /* Añade un contorno negro */
  font-family: var(--font-cuerpo);
}
.constructor-vivienda{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  gap:2rem;
  margin: 0;
  height: 27rem;
  
}
.constructor, .vivienda{
  
  display: flex; 
  flex-direction: column;
  align-items: center; 
  margin:0;
  padding: 0;
  width: 30rem;
  height: 10rem;
  position: relative;

  
}

.constructor img{
  width: 4rem;  
  height: auto;
}
.vivienda img{
  width: 3rem;
  height: auto;
}
.constructor h3, .vivienda h3{
  
  font-size: var(--letra-h3);
  background-color: var(--naranja);
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  padding: 0.8rem;
  border-radius: 2.5rem;
  width: 20rem;
  

  
}
.constructor h3 strong, .vivienda h3 strong {
  font-weight: 900; /* O usa 800 si 900 es demasiado grueso */
  -webkit-text-stroke: 1.5px var(--gris-oscuro); /* Añade un contorno negro */
}
.constructor p, .vivienda p{
  
  font-size: var(--letra-p) ;
  background-color: var(--gris-claro);
  font-family: var(--font-cuerpo) !important;
  color: var(--gris-oscuro) !important;
  padding: 3rem 1.5rem 1rem 1.5rem;
  max-width: 30rem;
  border-radius: 2rem;
  min-height: 20rem;
  width: 25rem;
  text-align: justify;
  
}

#elemento1 {
  position: absolute;
  top: -50%;
  transform: translateY(-10%);
  z-index: 3; /* Mayor valor = más arriba */
}

#elemento2 {
  position: absolute;
  top: -40%;
  transform: translateY(-10%);
  z-index: 2; /* Debajo de elemento1 */
}

#elemento3 {
  position: absolute;
  top: -25%;
  z-index: 1; /* Debajo de elemento2 */
}


.proyectos-list{
  background-image: url('utils/inicio/BKproyectos.webp');
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  width: 100%;
  padding: 4rem;
  display: flex; 
  flex-direction: row;
  align-items: center; 
  text-align: left;
  justify-content: center;

}
.LogosProyectosDiv{
  width: 100%;
  margin:3rem 0 0 0;
  display: flex; 
  flex-direction: column;
  align-items: center; 
  text-align: center;
  
  
}
.LogosProyectos{
  width: 80%;
  border-radius: 2rem;
  
}
.proyectos-list-info{
  display: flex; 
  flex-direction: row;
  align-items: left; 
  justify-content: left; 
  text-align: left;

}

.proyectos-list-info-text{
  margin: 0;
  padding: 0;
  
}
.proyectos-list-info-text h1{
  padding: 0;
  margin: 0;
  font-size: var(--letra-h1);
  line-height: 1;
}
.proyectos-list-info-text p{
  padding: 0;
  margin: 0;
  font-size: var(--letra-p);
  line-height: 1.1;
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);

}

.lineaVertical{
  background-color: var(--naranja);
  width: 0.25rem;
  margin: 0 0.5rem 0 0;
  
}

.clientes{
  background-color:white;
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: center; 
  text-align: left;
  width: 100%;
  padding: 0 1rem 0 1rem;
}
.clientes-logo{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: left; 
  width: 30%;
}
.clientes-logo h1{
  font-size: var(--letra-h1);
  font-weight: bold;
  color: var(--gris-oscuro);
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
}
/* Estilo del círculo */
.circleDarkGrey {
  width: 2rem;  /* Ancho del círculo */
  height: 2rem;  /* Alto del círculo (igual al ancho para hacer un círculo) */
  background-color: var(--gris-oscuro);  /* Color gris del círculo */
  border-radius: 50%;  /* Hace que el div sea un círculo */
}
.ovalGrey {
  width: 7rem;  /* Ajusta el ancho del óvalo */
  height: 2rem;  /* Ajusta la altura del óvalo */
  background-color: var(--gris-claro);  /* Color morado del óvalo */
  border-radius: 3rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  position: relative;  /* Para posicionar el círculo dentro */
  display: flex;
  justify-content: flex-end; /* Alinea el círculo a la derecha */
  align-items: center; /* Centra el círculo verticalmente */
  padding-right: 0; /* Espacio a la derecha entre el círculo y el borde del óvalo */
  margin-right: 0.5rem;
}
.clientes-info{
  margin: 4rem;
}
.clientes-info li{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  margin-bottom: 0.5rem;
}
.grua{
  margin: 2rem;
  width: 18rem;
}
.constructor-vivienda-form{
  background-color: var(--naranja);
  display: flex; 
  flex-direction: row;
  align-items:center; 
  justify-content: space-around; 
  width: 100%;
  
}
.mensaje-constructor-vivienda{
  width: 25%;
  margin: 1rem 0 1rem 7rem;
  text-align: left;
}
.mensaje-constructor-vivienda h2{
  font-size: var(--letra-h2);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  text-align: left;
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
  
}
.mensaje-constructor-vivienda h3{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  font-weight: 100;

}
.form-inicio {
  background-color: var(--gris-oscuro);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%; /* Ancho total de la tarjeta del formulario */
  box-sizing: border-box; /* Asegúrate de incluir padding en el cálculo del ancho */
  margin: 1rem 5rem 1rem 1rem;
  
}

.form-inicio form {
  width: 100%; /* Formulario ocupa todo el ancho del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem; /* Espaciado entre los elementos */
  text-align: left;
}

.form-inicio form > div {
  width: 100%; /* Cada div ocupa el ancho total del formulario */
}

.form-inicio label {
  display: block; /* Para que ocupe todo el ancho disponible */
  font-size: var(--letra-p);
  color: var(--gris-claro);
  margin-bottom: 0.5rem;
  font-family: var(--font-cuerpo);
}

.form-inicio input[type="text"],
.form-inicio input[type="email"]{
  width: 100%; /* Input ocupa todo el ancho de su contenedor padre */
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  font-size: var(--letra-p);
  font-family: var(--font-cuerpo);
}
.custom-select {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: var(--letra-p);
  cursor: pointer;
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
}

.custom-select:focus {
  border-color: var(--naranja);
  outline: none;
}

.custom-select option {
  padding: 10px;
  font-family: var(--font-cuerpo);
}
.autorizo-checkbox-inicio {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  margin-top: 0.5rem;
  text-align: left;
}

.autorizo-checkbox-inicio input[type="checkbox"] {
  margin: 0; /* Elimina cualquier margen por defecto */
  width: auto; /* Asegúrate de que no se extienda horizontalmente */
  height: auto; /* Ajusta la altura al tamaño predeterminado */
  font-weight: bold;
}

.autorizo-checkbox-inicio label {
  font-size: var(--letra-p);
  color: var(--gris-claro);
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1.5rem; /* Para un alineamiento vertical más limpio */
  gap: 0.5rem; /* Espaciado entre los elementos internos */
  font-family: var(--font-cuerpo);
  
}

/*CONOCENOS*/
.conocenos{
  display: flex; 
  flex-direction: column;
  align-items: center; 
}

.somos{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-evenly;  
  width: 100%;
  padding: 1rem 4rem 1rem 4rem;
  

}
.ImagenSomos{
  width: 25%;
  height: auto;
}
.lineaVerticalGris{
  background-color: var(--gris-oscuro);
  height: 20rem;
  width: 0.25rem;
}
.somos p{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  width: 50%;
  text-align: justify;
  letter-spacing: -0.02em; 
  font-weight: 300;
}
.valores{
  background-color: var(--gris-oscuro);
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-evenly;  
  width: 100%;
  padding: 1rem 4rem 1rem 4rem;
}
.circleOrange {
  width: 5rem;  /* Ancho del círculo */
  height: 5rem;  /* Alto del círculo (igual al ancho para hacer un círculo) */
  background-color: var(--naranja);  /* Color gris del círculo */
  border-radius: 5rem;  /* Hace que el div sea un círculo */
}
.ovalGreyConocenos {
  width: 19rem;  /* Ajusta el ancho del óvalo */
  height: 5rem;  /* Ajusta la altura del óvalo */
  background-color: var(--gris-claro);  /* Color morado del óvalo */
  border-radius: 3rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  position: relative;  /* Para posicionar el círculo dentro */
  display: flex;
  justify-content: flex-end; /* Alinea el círculo a la derecha */
  align-items: center; /* Centra el círculo verticalmente */
  padding-right: 0rem; /* Espacio a la derecha entre el círculo y el borde del óvalo */
  margin-right: 0.5rem;
}
.ovalGreyConocenos h1{
  color: var(--gris-oscuro);
  font-family: var(--font-titulos);
  margin: 0 0.35rem 0 0;
  padding: 0;
  font-weight: bold;
  font-size: var(--letra-h2);
  line-height: 1;
  width: 60%;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
}
.valores-list{
  display: flex; 
  flex-direction: column;
  align-items: flex-start; 
  justify-content: space-evenly;  
  text-align: justify;
  width: 55%;
  height: auto;

}
.ovalGreyValores{
  
  background-color: var(--gris-claro);  /* Color morado del óvalo */
  border-radius: 2rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  position: relative;  /* Para posicionar el círculo dentro */
  display: flex;
  justify-content: flex-start; /* Alinea el círculo a la derecha */
  align-items: center; /* Centra el círculo verticalmente */
  margin: 0 1rem 0 0;
  
}
.ovalGreyValores h1{
  color: var(--gris-oscuro);
  font-family: var(--font-titulos);
  font-weight: bold;
  margin: 0;
  padding: 0.2rem 0.35rem 0.2rem 0.35rem;
  font-size: var(--letra-h3);
  -webkit-text-stroke: 1.5px var(--gris-oscuro); 
}
.valores-list-texto p{
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  font-size: var(--letra-h3);
  padding: 0;
  margin: 0;
  font-weight: 100;
}
.valores-list-texto{
  display: flex; 
  flex-direction: column;
  align-items: flex-start; 
  justify-content: space-evenly;  
  margin:0.5rem
}
.valores-list-texto-alt{
  display: flex; 
  flex-direction: row;
  align-items:baseline; 
  justify-content: space-evenly;  
  text-align: justify;
}

.mision-vision{
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: space-evenly;  
  padding: 1rem 4rem 1rem 4rem;
  width: 100%;
  background-color: var(--gris-claro);

}
.mision-vision-detalle{
  display: flex; 
  flex-direction: row;
  align-items:center; 
  justify-content: center; 
  text-align: center;

}
.logo-mision, .logo-vision{
  
  border-radius: 3rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  position: relative;  /* Para posicionar el círculo dentro */
  display: flex;
  justify-content: center; /* Alinea el círculo a la derecha */
  align-items: center; /* Centra el círculo verticalmente */
  min-height: 10rem;
  margin:1rem;
  width: 90%;
}
.logo-mision img, .logo-vision img{
  height: 100%;
}

.mision, .vision{
  width: 100%;
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content:center;
  text-align: center;
  margin: 1rem 0 0 0;
}
.mision-texto,.vision-texto{
  background-image: url('utils/conocenos/Cuadro-MV.webp');
  height: 30rem;
  background-size: contain; /* Ajusta la imagen para que se muestre completa */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita que la imagen se repita */
  margin:1rem;
  width:25rem;
  padding: 0.5rem 0 0 0;
  height: 25rem;
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content:flex-start;
  text-align: center;

}
.mision-vision-detalle h1{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  padding: 0;
  margin: 2rem 0 0 0;
}
.mision-vision-detalle p{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  padding: 1rem;
  margin: 0;
  text-align: justify;
}
.mision-vision-contactanos{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: space-between; 
  width: 50%;

}
.mision-vision-contactanos h1{
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  text-align: center;
  
}

.btnNaranja{
  display: inline-block; 
  width: auto;  
  height: auto ; 
  color: var(--gris-oscuro);
  background: var(--naranja);
  border-radius: 2rem;
  margin:0 0 1rem 0;
  text-align: center;
  cursor: pointer; 
  padding: 0 1rem 0 1rem;
  
  
}
.btnNaranja a{
  font-size: var(--letra-h2) !important;
  font-weight: bold;
  
}
.constructor-vivienda-form-conocenos{
  background-color: white;
  display: flex; 
  flex-direction: row;
  align-items:center; 
  justify-content: space-around; 
  width: 100%;
  
}
.mensaje-constructor-vivienda-conocenos{
  width: 25%;
  margin: 1rem 0 1rem 7rem;
  text-align: left;
}
.mensaje-constructor-vivienda-conocenos h2{
  font-size: var(--letra-h2);
  color: var(--naranja);
  font-family: var(--font-titulos);
  text-align: left;
  -webkit-text-stroke: 2px var(--naranja); /* Añade un contorno negro */
  
}
.mensaje-constructor-vivienda-conocenos h3{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  font-weight: 100;

}
.form-conocenos {
  background-color: var(--gris-oscuro);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 35%; /* Ancho total de la tarjeta del formulario */
  box-sizing: border-box; /* Asegúrate de incluir padding en el cálculo del ancho */
  margin: 1rem 10rem 1rem 1rem;
  
}

.form-conocenos form {
  width: 100%; /* Formulario ocupa todo el ancho del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem; /* Espaciado entre los elementos */
  text-align: left;
}

.form-conocenos form > div {
  width: 100%; /* Cada div ocupa el ancho total del formulario */
}

.form-conocenos label {
  display: block; /* Para que ocupe todo el ancho disponible */
  font-size: var(--letra-p);
  color: var(--gris-claro);
  margin-bottom: 0.5rem;
  font-family: var(--font-cuerpo);
}

.form-conocenos input[type="text"],
.form-conocenos input[type="email"]{
  width: 100%; /* Input ocupa todo el ancho de su contenedor padre */
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  font-size: var(--letra-p);
  font-family: var(--font-cuerpo);
}
.custom-select {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  background-color: white;
  font-size: var(--letra-p);
  cursor: pointer;
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
}

.custom-select:focus {
  border-color: var(--naranja);
  outline: none;
}

.custom-select option {
  padding: 10px;
  font-family: var(--font-cuerpo);
}
.autorizo-checkbox-conocenos {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  margin-top: 0.5rem;
  text-align: center;
}

.autorizo-checkbox-conocenos input[type="checkbox"] {
  margin: 0; /* Elimina cualquier margen por defecto */
  width: auto; /* Asegúrate de que no se extienda horizontalmente */
  height: auto; /* Ajusta la altura al tamaño predeterminado */
  font-weight: bold;
}

.autorizo-checkbox-conocenos label {
  font-size: var(--letra-p);
  color: var(--gris-claro);
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1.5rem; /* Para un alineamiento vertical más limpio */
  gap: 0.5rem; /* Espaciado entre los elementos internos */
  font-family: var(--font-cuerpo);
}


/*Sauces*/
.sauces{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  width: 100%;
  margin: 2rem 0 1rem 0;
  padding: 0;
}


.banner-sauces{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
  background-image: url('utils/sauces/Banner.webp');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  margin: 0;
  height: auto;
}
.logoSauces{
  margin-top: 1rem;
}
.banner-sauces-info{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 
  padding: 2rem 5rem 1rem 5rem;
  height: 40rem;
  width: 100%;
}
.banner-sauces-info h1{
  color:var(--gris-claro);
  font-size: var(--letra-h1);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 3px var(--gris-claro); /* Añade un contorno negro */
  padding: 0;
  margin: 0;
  line-height: 1; 
  width: 10rem;

}
.banner-sauces-info h3{
  color:var(--gris-claro);
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--gris-claro); /* Añade un contorno negro */
  padding: 0;
  margin: 0 0 1rem 0;
  line-height: 1; 
  width: 60%;

}

.banner-ubicacion-sauces{
  display: flex; 
  flex-direction: row;
  align-items:center; 
  justify-content: space-between; 
 

}
.banner-ubicacion-sauces h2{
  font-size: var(--letra-h2);
  font-family: var(--font-titulos);
  color: var(--gris-claro);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--gris-claro); /* Añade un contorno negro */
  letter-spacing: -0.02em;

}

.form-sauces {
  background-color: var(--verde-sauces);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 52rem; /* Ancho total de la tarjeta del formulario */
  box-sizing: border-box; /* Asegúrate de incluir padding en el cálculo del ancho */
  margin: 1rem 5rem 1rem 1rem;
}

.form-sauces form {
  width: 100%; /* Formulario ocupa todo el ancho del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem; /* Espaciado entre los elementos */
}

.form-sauces form > div {
  width: 100%; /* Cada div ocupa el ancho total del formulario */
}

.form-sauces label {
  display: block; /* Para que ocupe todo el ancho disponible */
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  margin-bottom: 0.5rem;
  font-family: var(--font-cuerpo);
}

.form-sauces input[type="text"],
.form-sauces input[type="email"]{
  width: 100%; /* Input ocupa todo el ancho de su contenedor padre */
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  font-size: var(--letra-p);
  font-family: var(--font-cuerpo);
}
.autorizo-checkbox-sauces {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  margin-top: 0.5rem;
  
}

.autorizo-checkbox-sauces input[type="checkbox"] {
  margin: 0; /* Elimina cualquier margen por defecto */
  width: auto; /* Asegúrate de que no se extienda horizontalmente */
  height: auto; /* Ajusta la altura al tamaño predeterminado */
  font-weight: bold;
}

.autorizo-checkbox-sauces label {
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  display: flex;
  align-items: center;
  line-height: 1.5rem; /* Para un alineamiento vertical más limpio */
  gap: 0.5rem; /* Espaciado entre los elementos internos */
  font-family: var(--font-cuerpo);
}


.form-sauces div{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 

}
.btnGrisSauces {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-claro);
  background: var(--gris-oscuro);
  border-radius: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnGrisSauces a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnGrisSauces:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}
.btnGrisSauces2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-claro);
  background: var(--gris-oscuro);
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 20rem !important;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
  
} 
.btnGrisSauces2 a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnGrisSauces2:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

.proyecto-sauces{
  background-color: var(--verde-sauces);
  color: var(--gris-oscuro);
  font-family: var(--font-titulos);
  width: 100%;

}
.proyecto-sauces h1{

  font-size: var(--letra-h1);
  text-align: center;
  padding: 1rem 0 1rem 0;
  margin: 0;
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  

}
.descubre-sauces{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  gap:0;

}


.descubre-sauces p{
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
  width: 50rem;
  font-size: var(--letra-h3);
  text-align: justify;
  padding: 3rem 3rem 0 3rem;
  
}
.info-y-avance-sauces{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
  
}
.galerias-fotos-sauces {
  position: relative;
  width: 60%;
  max-width: 600px; /* Ajusta el tamaño según sea necesario */
  display: flex;
  justify-content: center;
  align-items: center;
  
  
}


.contenedor-foto {
  width: 100%;
  height: auto;
  max-width: 30rem;
  max-height: 20rem; 
}

.contenedor-foto img {
  /*
  width: 100%;
  height: auto;
  display: block;*/
  max-width: 100%;  /* Evita que se salga del contenedor */
  max-height: 20rem; /* Asegura que respete la altura máxima */
  height: auto;     /* Mantiene la proporción */
  width: auto; 
}

.btn-flecha {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #333;
  font-size: var(--letra-boton);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.btn-flecha.izquierda {
  left: 10px; /* Posición de la flecha izquierda */
}

.btn-flecha.derecha {
  right: 10px; /* Posición de la flecha derecha */
}

.espacios-sauces{
  background-color: var(--gris-oscuro);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 1rem;
}
.espacios-sauces h1{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  margin:1rem;
  padding: 0;
  -webkit-text-stroke: 3px var(--gris-claro); /* Añade un contorno negro */
  font-weight: bold;
  line-height: 1;
  width: 30%;
  text-align: center;
}
.espacios-sauces p{
  font-size: var(--letra-h2);
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  width: 30%;
  text-align: justify;
  padding: 0 0.5rem 0 0.5rem;
}
.lineaVerticalVerde{
  background-color: var(--verde-sauces);
  width: 0.4rem;
  height: 90%;
  margin: 1rem;

}

.info-sauces {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
 }
.info-sauces-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:1rem;

}
.info-sauces-img img{
  margin:0.5rem;
  height: 2.7rem;
  

}
.info-sauces-texto{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin:0.5rem;
  
  

}
.info-sauces-texto p{
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
  text-align: left;
  height: 2.7rem;
}

.planta-sauces{
  background-color: var(--verde-sauces);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.planta-sauces h1{
  font-family: var(--font-titulos);
  color: var(--gris-claro);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
}
.planta-sauces img{
  width: 55%;
  text-align: center;
}
.comodidades-sauces{
  background-image: url('utils/sauces/Banner2.webp');
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 2rem;

}
.comodidades-sauces h1{
  font-family: var(--font-titulos);
  color: var(--gris-claro);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
  width: 50%;
  line-height: 1;
}
.comodidades-sauces-lista{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  margin: 1rem;

}
.salon,.juegos,.sendero,.cancha,.parqueadero{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

}
.comodidades-sauces-lista img{
  margin:0.5rem 0.5rem 0 0;

}
.comodidades-sauces-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  font-family: var(--font-cuerpo);
  color: var(--gris-claro);
  margin: 0;
  padding: 0;


}
.ubicacion-sauces{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;
}
.ubicacion-sauces-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;

}
.ubicacion-sauces-info h1{
  font-family: var(--font-titulos);
  font-size: var(--letra-h1);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.01em;
  margin:0.5rem;
  padding: 0;
}
.ubicacion-sauces-info h2{
  font-family: var(--font-titulos);
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  letter-spacing: -0.02em;
  margin:0;
  padding: 0;
}
.ubicacion-sauces-maps{
  width: 50%;
}
.ubicacion-sauces-maps img{
  width: 100%;

}
.sauces-eleccion{
  background-color: var(--verde-sauces);
  width: 100%;
  padding:2rem;
}
.sauces-eleccion h1,.sauces-eleccion h2{
  font-size: var(--letra-h1);
  color: var(--gris-oscuro);
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: normal;
}
.sauces-eleccion h2{
  font-weight: bold;
}
.sauces-info{
  display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 background-color: var(--gris-oscuro);
 width: 100%;
 padding: 1rem;
 gap:3rem;
}
.sauces-info p{
  color: var(--gris-claro);
  font-size: var(--letra-h3);
  font-weight: bold;
  text-align: center;
}
.sauces-info img{
  text-align: center;
}
.sauces-info div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}

.contactenos-sauces{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 2rem 0 2rem 4rem;
  height: auto;
}
.contactenos-sauces-info{
  width: 100%;
  padding: 2rem 0 2rem 3rem;
}

.contactenos-sauces-info p{
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h3);
  width: 100%;
}

.contactenos-sauces-info h1{
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h1);
  font-weight: bold;
  letter-spacing: -0.01em;
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  width: 30rem;
}



.btnGrisSaucesContacto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-claro);
  background: var(--gris-oscuro);
  border-radius: 2rem;
  margin: 2rem 0 2rem 0;
  cursor: pointer;
  padding: 0.5rem;
  width: 30%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnGrisSaucesContacto a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnGrisSaucesContacto:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

/*Piamonte*/

.piamonte{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  width: 100%;
  margin: 2rem 0 1rem 0;
  padding: 0;
}

.banner-piamonte{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
  background-image: url('utils/piamonte/Banner.webp');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  margin: 0;
  height: auto;
}
.banner-piamonte-info{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 
  padding: 1rem 5rem 1rem 5rem;
  height: 40rem;
  width: 100%;
}
.banner-piamonte-info h1{
  color:var(--gris-oscuro);
  font-size: var(--letra-h1);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  padding: 0;
  margin: 0;
  line-height: 1; 
  width: 40%;
}

.banner-piamonte-info h3{
  color:var(--gris-oscuro);
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  padding: 0;
  margin: 0 0 1rem 0;
  line-height: 1; 
  width: 60%;

}

.banner-ubicacion-piamonte{
  display: flex; 
  flex-direction: row;
  align-items:center; 
  justify-content: space-between; 
  gap:0.5rem

}
.banner-ubicacion-piamonte h2{
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: -0.02em;
}

.form-piamonte {
  background-color: var(--verde-oscuro-piamonte);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 52rem; /* Ancho total de la tarjeta del formulario */
  box-sizing: border-box; /* Asegúrate de incluir padding en el cálculo del ancho */
  margin: 1rem 5rem 1rem 1rem;
}

.form-piamonte form {
  width: 100%; /* Formulario ocupa todo el ancho del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem; /* Espaciado entre los elementos */
}

.form-piamonte form > div {
  width: 100%; /* Cada div ocupa el ancho total del formulario */
}

.form-piamonte label {
  display: block; /* Para que ocupe todo el ancho disponible */
  font-size: var(--letra-p);
  color: var(--gris-claro);
  margin-bottom: 0.5rem;
  font-family: var(--font-cuerpo);
}

.form-piamonte input[type="text"],
.form-piamonte input[type="email"]{
  width: 100%; /* Input ocupa todo el ancho de su contenedor padre */
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  font-size: var(--letra-p);
  font-family: var(--font-cuerpo);
}
.autorizo-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  margin-top: 0.5rem;
}

.autorizo-checkbox input[type="checkbox"] {
  margin: 0; /* Elimina cualquier margen por defecto */
  width: auto; /* Asegúrate de que no se extienda horizontalmente */
  height: auto; /* Ajusta la altura al tamaño predeterminado */
  font-weight: bold;
}

.autorizo-checkbox label {
  font-size: var(--letra-p);
  color: var(--gris-claro);
  display: flex;
  align-items: center;
  line-height: 1.5rem; /* Para un alineamiento vertical más limpio */
  gap: 0.5rem; /* Espaciado entre los elementos internos */
  font-family: var(--font-cuerpo);
}


.form-piamonte div{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 

}
.btnVerdePiamonte {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-oscuro);
  background: var(--verde-claro-piamonte);
  border-radius: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnNaranjaPiamonte a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnNaranjaPiamonte:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}
.btnVerdePiamonte2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-oscuro);
  background: var(--verde-claro-piamonte);
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 20rem !important;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnVerdePiamonte2 a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnVerdePiamonte2:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

.proyecto-piamonte{
  background-color: var(--verde-claro-piamonte);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  width: 100%;

}
.proyecto-piamonte h1{

  font-size: var(--letra-h1);
  text-align: center;
  padding: 1rem 0 1rem 0;
  margin: 0;
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
  

}
.descubre-piamonte{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: row;
  width: 100%;

}
.descubre-piamonte-texto{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
}
.descubre-piamonte p{
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
  width: 100%;
  font-size: var(--letra-h3);
  text-align: justify;
  padding: 3rem 3rem 0 3rem;
  

}
.info-y-avance-piamonte{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
  
}
.galerias-fotos-piamonte {
  position: relative;
  width: 60%;
  max-width: 600px; /* Ajusta el tamaño según sea necesario */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.contenedor-foto {
  width: 100%;
  height: auto;
}

.contenedor-foto img {
  width: 100%;
  height: auto;
  display: block;
}

.btn-flecha {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #333;
  font-size: var(--letra-boton);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.btn-flecha.izquierda {
  left: 10px; /* Posición de la flecha izquierda */
}

.btn-flecha.derecha {
  right: 10px; /* Posición de la flecha derecha */
}

.espacios-piamonte{
  background-color: var(--verde-oscuro-piamonte);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 1rem;
}
.espacios-piamonte h1{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  margin:1rem;
  padding: 0;
  -webkit-text-stroke: 3px var(--gris-claro); /* Añade un contorno negro */
  font-weight: bold;
  line-height: 1;
  width: 30%;
  text-align: center;
}
.espacios-piamonte p{
  font-size: var(--letra-h2);
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  width: 30%;
  text-align: justify;
  padding: 0.5rem;
}
.lineaVerticalVerde{
  background-color: var(--verde-claro-piamonte);
  width: 0.4rem;
  height: 90%;
  margin: 1rem;

}

.info-piamonte {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
 }
.info-piamonte-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:1rem;

}
.info-piamonte-img img{
  margin:0.5rem;
  height: 3rem;
  

}
.info-piamonte-texto{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin:0.5rem;
  
  

}
.info-piamonte-texto p{
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 0.6rem 0 0.6rem 0;
  text-align: left;
  height: 3rem;
  line-height: 1;
}

.planta-piamonte{
  background-color: var(--verde-claro-piamonte);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.planta-piamonte h1{
  font-family: var(--font-titulos);
  color: var(--gris-claro);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
}
.planta-piamonte img{
  width: 55%;
  text-align: center;
}
.comodidades-piamonte{
  background-image: url('utils/piamonte/Banner2.webp');
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 2rem;

}
.lineaVerticalVerdeOscuro{
  background-color: var(--verde-oscuro-piamonte);
  width: 0.4rem;
  height: 70%;
  margin: 1rem;

}
.comodidades-piamonte h1{
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  width: 50%;
  line-height: 1;
}
.comodidades-piamonte-lista{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  margin: 1rem;

}
.salon,.juegos,.sendero,.cancha{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

}
.comodidades-piamonte-lista img{
  margin:0.5rem 0.5rem 0 0;

}
.comodidades-piamonte-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
  margin: 0;
  padding: 0;


}
.ubicacion-piamonte{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;
}
.ubicacion-piamonte-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;

}
.ubicacion-piamonte-info h1{
  font-family: var(--font-titulos);
  font-size: var(--letra-h1);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.01em;
  margin:0.5rem;
  padding: 0;
}
.ubicacion-piamonte-info h2{
  font-family: var(--font-titulos);
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  letter-spacing: -0.02em;
  margin:0;
  padding: 0;
}
.ubicacion-piamonte-maps{
  width: 50%;
}
.ubicacion-piamonte-maps img{
  width: 100%;

}
.piamonte-eleccion{
  background-color: var(--verde-oscuro-piamonte);
  width: 100%;
  padding:2rem;
}
.piamonte-eleccion h1,h2{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

.piamonte-info{
  display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 background-color: var(--verde-claro-piamonte);
 width: 100%;
 padding: 1rem;
 gap:3rem;
}
.piamonte-info p{
  color: var(--gris-claro);
  font-size: var(--letra-h3);
  font-weight: bold;
  text-align: center;
}
.piamonte-info img{
  text-align: center;
}
.piamonte-info div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.contactenos-piamonte{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 2rem 0 2rem 4rem;
  height: auto;
}
.contactenos-piamonte-info{
  width: 100%;
  padding: 2rem 0 2rem 3rem;
}
.contactenos-piamonte-info h1{
  font-family: var(--font-titulos);
  color: var(--verde-oscuro-piamonte);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h1);
  font-weight: bold;
  letter-spacing: -0.01em;
  -webkit-text-stroke: 2px var(--verde-oscuro-piamonte); /* Añade un contorno negro */
  width: 100%;
}
.contactenos-piamonte-info p{
  font-family: var(--font-titulos);
  color: var(--verde-oscuro-piamonte);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h3);
  width: 100%;
}

.btnVerdePiamonteContacto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-oscuro);
  background: var(--verde-claro-piamonte);
  border-radius: 2rem;
  margin: 2rem 0 2rem 0;
  cursor: pointer;
  padding: 0.5rem;
  width: 30%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnVerdePiamonteContacto a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnVerdePiamonteContacto:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

/*Santa Clara*/

.santaclara{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  width: 100%;
  margin: 2rem 0 1rem 0;
  padding: 0;
}

.banner-santaclara{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
  background-image: url('utils/santaclara/Banner.webp');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  margin: 0;
  height: auto;
}
.banner-santaclara-info{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: center; 
  padding: 1rem 5rem 1rem 5rem;
  height: 40rem;
  width: 100%;
}
.banner-santaclara-info h1{
  color:var(--gris-oscuro);
  font-size: var(--letra-h1);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  padding: 0;
  margin: 7rem 0 0 0;
  line-height: 1; 
  width: 40%;
  padding: 0;
}

.banner-santaclara-info h3{
  color:var(--gris-oscuro);
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  padding: 0;
  margin: 0 0 1rem 0;
  line-height: 1; 
  width: 60%;

}
.banner-santaclara-info img{
  width: 10rem;
}

.banner-ubicacion-santaclara{
  display: flex; 
  flex-direction: row;
  align-items:center; 
  justify-content: space-between; 
  gap:0.5rem

}
.banner-ubicacion-santaclara h2{
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: -0.02em;
}
.banner-ubicacion-santaclara img{
  width: 2rem;
}

.form-santaclara {
  background-color: var(--verde-oscuro-santaclara);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 52rem; /* Ancho total de la tarjeta del formulario */
  box-sizing: border-box; /* Asegúrate de incluir padding en el cálculo del ancho */
  margin: 1rem 5rem 1rem 1rem;
}

.form-santaclara form {
  width: 100%; /* Formulario ocupa todo el ancho del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem; /* Espaciado entre los elementos */
}

.form-santaclara form > div {
  width: 100%; /* Cada div ocupa el ancho total del formulario */
}

.form-santaclara label {
  display: block; /* Para que ocupe todo el ancho disponible */
  font-size: var(--letra-p);
  color: var(--gris-claro);
  margin-bottom: 0.5rem;
  font-family: var(--font-cuerpo);
}

.form-santaclara input[type="text"],
.form-santaclara input[type="email"]{
  width: 100%; /* Input ocupa todo el ancho de su contenedor padre */
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  font-size: var(--letra-p);
  font-family: var(--font-cuerpo);
}
.autorizo-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  margin-top: 0.5rem;
}

.autorizo-checkbox input[type="checkbox"] {
  margin: 0; /* Elimina cualquier margen por defecto */
  width: auto; /* Asegúrate de que no se extienda horizontalmente */
  height: auto; /* Ajusta la altura al tamaño predeterminado */
  font-weight: bold;
}

.autorizo-checkbox label {
  font-size: var(--letra-p);
  color: var(--gris-claro);
  display: flex;
  align-items: center;
  line-height: 1.5rem; /* Para un alineamiento vertical más limpio */
  gap: 0.5rem; /* Espaciado entre los elementos internos */
  font-family: var(--font-cuerpo);
}


.form-santaclara div{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 

}
.btnVerdeSantaClara {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-oscuro);
  background: var(--verde-claro-santaclara);
  border-radius: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnNaranjaSantaClara a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnNaranjaSantaClara:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}
.btnVerdeSantaClara2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-oscuro);
  background: var(--verde-claro-santaclara);
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 20rem !important;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnVerdeSantaClara2 a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnVerdeSantaClara2:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

.proyecto-santaclara{
  background-color: var(--verde-claro-santaclara);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  width: 100%;

}
.proyecto-santaclara h1{

  font-size: var(--letra-h1);
  text-align: center;
  padding: 1rem 0 1rem 0;
  margin: 0;
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
  

}
.descubre-santaclara{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: row;
  width: 100%;

}
.descubre-santaclara-texto{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
}
.descubre-santaclara p{
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
  width: 100%;
  font-size: var(--letra-h3);
  text-align: justify;
  padding: 3rem 3rem 0 3rem;
  

}
.info-y-avance-santaclara{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
  
}
.galerias-fotos-santaclara {
  position: relative;
  width: 60%;
  max-width: 600px; /* Ajusta el tamaño según sea necesario */
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.contenedor-foto {
  width: 100%;
  height: auto;
}

.contenedor-foto img {
  width: 100%;
  height: auto;
  display: block;
}

.btn-flecha {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #333;
  font-size: var(--letra-boton);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.btn-flecha.izquierda {
  left: 10px; /* Posición de la flecha izquierda */
}

.btn-flecha.derecha {
  right: 10px; /* Posición de la flecha derecha */
}

.espacios-santaclara{
  background-color: var(--verde-oscuro-santaclara);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 1rem;
}
.espacios-santaclara h1{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  margin:1rem;
  padding: 0;
  -webkit-text-stroke: 3px var(--gris-claro); /* Añade un contorno negro */
  font-weight: bold;
  line-height: 1;
  width: 30%;
  text-align: center;
}
.espacios-santaclara p{
  font-size: var(--letra-h2);
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  width: 30%;
  text-align: justify;
  padding: 0.5rem;
}
.lineaVerticalVerde{
  background-color: var(--verde-claro-santaclara);
  width: 0.4rem;
  height: 90%;
  margin: 1rem;

}

.info-santaclara {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
 }
.info-santaclara-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:1rem;

}
.info-santaclara-img img{
  margin:0.5rem;
  height: 3rem;
  

}
.info-santaclara-texto{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin:0.5rem;
  
  

}
.info-santaclara-texto p{
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 0.6rem 0 0.6rem 0;
  text-align: left;
  height: 3rem;
  line-height: 1;
}

.planta-santaclara{
  background-color: var(--verde-claro-santaclara);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.planta-santaclara h1{
  font-family: var(--font-titulos);
  color: var(--gris-claro);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
}
.planta-santaclara img{
  width: 55%;
  text-align: center;
}
.comodidades-santaclara{
  background-image: url('utils/santaclara/Banner2.webp');
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 2rem;

}
.lineaVerticalVerdeOscuro{
  background-color: var(--verde-oscuro-santaclara);
  width: 0.4rem;
  height: 70%;
  margin: 1rem;

}
.comodidades-santaclara h1{
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  width: 50%;
  line-height: 1;
}
.comodidades-santaclara-lista{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  margin: 1rem;

}
.salon,.juegos,.sendero,.cancha{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

}
.comodidades-santaclara-lista img{
  margin:0.5rem 0.5rem 0 0;
  width: 2rem;

}
.comodidades-santaclara-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
  margin: 0;
  padding: 0;


}
.ubicacion-santaclara{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;
}
.ubicacion-santaclara-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: left;
  width: 40%;

}
.ubicacion-santaclara-info h1{
  font-family: var(--font-titulos);
  font-size: var(--letra-h1);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.01em;
  margin:0.5rem;
  padding: 0;
}
.ubicacion-santaclara-info h2{
  font-family: var(--font-titulos);
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  letter-spacing: -0.02em;
  margin:0;
  padding: 0;
}
.ubicacion-santaclara-info h3{
  font-family: var(--font-titulos);
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  letter-spacing: -0.02em;
  -webkit-text-stroke: 1px var(--gris-oscuro); 
  margin:0;
  padding: 0;
}
.ubicacion-santaclara-info img {
  width: 7rem;
}
.ubicacion-santaclara-maps{
  width: 50%;
}
.ubicacion-santaclara-maps img{
  width: 100%;

}
.santaclara-eleccion{
  background-color: var(--verde-oscuro-santaclara);
  width: 100%;
  padding:2rem;
}
.santaclara-eleccion h1,h2{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

.santaclara-info{
  display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 background-color: var(--verde-claro-santaclara);
 width: 100%;
 padding: 1rem;
 gap:3rem;
}
.santaclara-info p{
  color: var(--gris-claro);
  font-size: var(--letra-h3);
  font-weight: bold;
  text-align: center;
}
.santaclara-info img{
  text-align: center;
}
.santaclara-info div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.contactenos-santaclara{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 2rem 0 2rem 4rem;
  height: auto;
}
.contactenos-santaclara-info{
  width: 100%;
  padding: 2rem 0 2rem 3rem;
}
.contactenos-santaclara-info h1{
  font-family: var(--font-titulos);
  color: var(--verde-oscuro-santaclara);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h1);
  font-weight: bold;
  letter-spacing: -0.01em;
  -webkit-text-stroke: 2px var(--verde-oscuro-santaclara); /* Añade un contorno negro */
  width: 100%;
}
.contactenos-santaclara-info p{
  font-family: var(--font-titulos);
  color: var(--verde-oscuro-santaclara);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h3);
  width: 100%;
}

.btnVerdeSantaClaraContacto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-oscuro);
  background: var(--verde-claro-santaclara);
  border-radius: 2rem;
  margin: 2rem 0 2rem 0;
  cursor: pointer;
  padding: 0.5rem;
  width: 30%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnVerdeSantaClaraContacto a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnVerdeSantaClaraContacto:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

/*Acacias*/


.acacias{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  width: 100%;
  margin: 2rem 0 1rem 0;
  padding: 0;
}
.logoAcacias{
  margin-top: 1rem;
  width: 20rem;
}

.banner-acacias{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
  background-image: url('utils/acacias/Banner.webp');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  margin: 0;
  height: auto;
}
.banner-acacias-info{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 
  padding: 1rem 5rem 1rem 5rem;
  height: 40rem;
  width: 100%;
}
.banner-acacias-info h1{
  color:var(--gris-oscuro);
  font-size: var(--letra-h1);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  padding: 0;
  margin: 0;
  line-height: 1; 
  width: 40%;
}

.banner-acacias-info h3{
  color:var(--gris-oscuro);
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  padding: 0;
  margin: 0 0 1rem 0;
  line-height: 1; 
  width: 60%;

}

.banner-ubicacion-acacias{
  display: flex; 
  flex-direction: row;
  align-items:center; 
  justify-content: space-between; 
  gap:0.5rem

}
.banner-ubicacion-acacias h2{
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: -0.02em;
}

.form-acacias {
  background-color: var(--gris-oscuro-acacias);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 52rem; /* Ancho total de la tarjeta del formulario */
  box-sizing: border-box; /* Asegúrate de incluir padding en el cálculo del ancho */
  margin: 1rem 5rem 1rem 1rem;
}

.form-acacias form {
  width: 100%; /* Formulario ocupa todo el ancho del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem; /* Espaciado entre los elementos */
}

.form-acacias form > div {
  width: 100%; /* Cada div ocupa el ancho total del formulario */
}

.form-acacias label {
  display: block; /* Para que ocupe todo el ancho disponible */
  font-size: var(--letra-p);
  color: var(--gris-claro);
  margin-bottom: 0.5rem;
  font-family: var(--font-cuerpo);
}

.form-acacias input[type="text"],
.form-acacias input[type="email"]{
  width: 100%; /* Input ocupa todo el ancho de su contenedor padre */
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  font-size: var(--letra-p);
  font-family: var(--font-cuerpo);
}
.autorizo-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  margin-top: 0.5rem;
}

.autorizo-checkbox input[type="checkbox"] {
  margin: 0; /* Elimina cualquier margen por defecto */
  width: auto; /* Asegúrate de que no se extienda horizontalmente */
  height: auto; /* Ajusta la altura al tamaño predeterminado */
  font-weight: bold;
}

.autorizo-checkbox label {
  font-size: var(--letra-p);
  color: var(--gris-claro);
  display: flex;
  align-items: center;
  line-height: 1.5rem; /* Para un alineamiento vertical más limpio */
  gap: 0.5rem; /* Espaciado entre los elementos internos */
  font-family: var(--font-cuerpo);
}


.form-acacias div{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 

}
.btnNaranjaAcacias {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-claro);
  background: var(--naranja);
  border-radius: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnNaranjaAcacias a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnNaranjaAcacias:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}
.btnNaranjaAcacias2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-claro);
  background: var(--naranja);
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 20rem !important;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnNaranjaAcacias2 a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnNaranjaAcacias2:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

.proyecto-acacias{
  background-color: var(--naranja);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  width: 100%;

}
.proyecto-acacias h1{

  font-size: var(--letra-h1);
  text-align: center;
  padding: 1rem 0 1rem 0;
  margin: 0;
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
  

}
.descubre-acacias{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: row;
  width: 100%;

}
.descubre-acacias-texto{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
}
.descubre-acacias p{
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
  width: 100%;
  font-size: var(--letra-h3);
  text-align: justify;
  padding: 3rem 3rem 0 3rem;
  

}
.info-y-avance-acacias{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
  
}
.galerias-fotos-acacias {
  position: relative;
  width: 60%;
  max-width: 600px; /* Ajusta el tamaño según sea necesario */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  
}

.contenedor-foto {
  width: 100%;
  height: auto;
}

.contenedor-foto img {
  width: 100%;
  height: auto;
  display: block;
}

.btn-flecha {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #333;
  font-size: var(--letra-boton);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.btn-flecha.izquierda {
  left: 10px; /* Posición de la flecha izquierda */
}

.btn-flecha.derecha {
  right: 10px; /* Posición de la flecha derecha */
}

.espacios-acacias{
  background-color: var(--gris-oscuro-acacias);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 1rem;
}
.espacios-acacias h1{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  margin:1rem;
  padding: 0;
  -webkit-text-stroke: 3px var(--gris-claro); /* Añade un contorno negro */
  font-weight: bold;
  line-height: 1;
  width: 30%;
  text-align: center;
}
.espacios-acacias p{
  font-size: var(--letra-h2);
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  width: 30%;
  text-align: justify;
  padding: 0 0.5rem 0 0.5rem;
}
.lineaVerticalNaranja{
  background-color: var(--naranja);
  width: 0.4rem;
  height: 90%;
  margin: 1rem;

}

.info-acacias {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
 }
.info-acacias-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:1rem;

}
.info-acacias-img img{
  margin:0.5rem;
  height: 3rem;
  

}
.info-acacias-texto{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin:0.5rem;
  
  

}
.info-acacias-texto p{
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 0.6rem 0 0.6rem 0;
  text-align: left;
  height: 3rem;
  line-height: 1;
}

.planta-acacias{
  background-color: var(--naranja);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.planta-acacias h1{
  font-family: var(--font-titulos);
  color: var(--gris-claro);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
}
.planta-acacias img{
  width: 55%;
  text-align: center;
}
.comodidades-acacias{
  background-image: url('utils/acacias/Banner2.webp');
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 2rem;

}

.comodidades-acacias h1{
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  width: 50%;
  line-height: 1;
}
.comodidades-acacias-lista{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  margin: 1rem;

}
.salon,.juegos,.sendero,.lago,.elemento{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

}
.comodidades-acacias-lista img{
  margin:0.5rem 0.5rem 0 0;
  width: 3rem;

}
.comodidades-acacias-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  font-family: var(--font-cuerpo);
  color: var(--gris-oscuro);
  margin: 0;
  padding: 0;


}
.ubicacion-acacias{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;
}
.ubicacion-acacias-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;

}
.ubicacion-acacias-info h1{
  font-family: var(--font-titulos);
  font-size: var(--letra-h1);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.01em;
  margin:0.5rem;
  padding: 0;
}
.ubicacion-acacias-info h2{
  font-family: var(--font-titulos);
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  letter-spacing: -0.02em;
  margin:0;
  padding: 0;
}
.ubicacion-acacias-info h3{
  font-family: var(--font-titulos);
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  margin:0;
  padding: 0;
  font-weight: bold;
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
}
.ubicacion-acacias-maps{
  width: 50%;
}
.ubicacion-acacias-maps img{
  width: 100%;

}
.acacias-eleccion{
  background-color: var(--naranja);
  width: 100%;
  padding:2rem;
}
.acacias-eleccion h1{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

.acacias-info{
  display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 background-color: var(--gris-oscuro-acacias);
 width: 100%;
 padding: 1rem;
 gap:3rem;
}
.acacias-info p{
  color: var(--gris-claro);
  font-size: var(--letra-h3);
  font-weight: bold;
  text-align: center;
}
.acacias-info img{
  text-align: center;
}
.acacias-info div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.contactenos-acacias{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 2rem 0 2rem 4rem;
  height: auto;
}
.contactenos-acacias-info{
  width: 100%;
  padding: 2rem 0 2rem 3rem;
}
.contactenos-acacias-info h1{
  font-family: var(--font-titulos);
  color: var(--gris-oscuro-acacias);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h1);
  font-weight: bold;
  letter-spacing: -0.01em;
  -webkit-text-stroke: 2px var(--gris-oscuro-acacias); /* Añade un contorno negro */
  width: 30rem;
}
.contactenos-acacias-info p{
  font-family: var(--font-titulos);
  color: var(--gris-oscuro-acacias);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h3);
  width: 100%;
}

.btnNaranjaAcaciasContacto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-claro);
  background: var(--naranja);
  border-radius: 2rem;
  margin: 2rem 0 2rem 0;
  cursor: pointer;
  padding: 0.5rem;
  width: 30%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnNaranjaAcaciasContacto a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnNaranjaAcaciasContacto:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

/*Alameda*/


.alameda{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  width: 100%;
  margin: 2rem 0 1rem 0;
  padding: 0;
}
.logoAlameda{
  height: 10rem;
  margin: 1rem 0 0 0;
}
.comodidades-alameda-lista img{
  height: 2rem;
  margin: 1rem 0 0 0;
}
.banner-alameda{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-between; 
  width: 100%;
  background-image: url('utils/alameda/Banner.webp');
  background-size: cover; 
  background-position: center; 
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  margin: 0;
  height: auto;
}
.banner-alameda-info{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 
  padding: 1rem 5rem 1rem 5rem;
  height: 40rem;
  width: 100%;
}
.banner-alameda-info h1{
  color:var(--azul-oscuro-alameda);
  font-size: var(--letra-h1);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 3px var(--azul-oscuro-alameda); /* Añade un contorno negro */
  padding: 0;
  margin: 0;
  line-height: 1; 
  width: 40%;
}


.banner-alameda-info h3{
  color:var(--azul-oscuro-alameda);
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--azul-oscuro-alameda); /* Añade un contorno negro */
  padding: 0;
  margin: 0 0 1rem 0;
  line-height: 1; 
  width: 60%;

}

.banner-ubicacion-alameda{
  display: flex; 
  flex-direction: row;
  align-items:flex-start; 
  justify-content: space-between; 
  gap:0.5rem

}
.banner-ubicacion-alameda h2{
  font-size: var(--letra-h3);
  font-family: var(--font-titulos);
  color: var(--azul-oscuro-alameda);
  font-weight: bold;
  -webkit-text-stroke: 1px var(--azul-oscuro-alameda); /* Añade un contorno negro */
  letter-spacing: -0.02em;
  text-align: left;
}

.form-alameda {
  background-color: var(--azul-oscuro-alameda);
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 52rem; /* Ancho total de la tarjeta del formulario */
  box-sizing: border-box; /* Asegúrate de incluir padding en el cálculo del ancho */
  margin: 1rem 5rem 1rem 1rem;
}

.form-alameda form {
  width: 100%; /* Formulario ocupa todo el ancho del contenedor */
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.3rem; /* Espaciado entre los elementos */
}

.form-alameda form > div {
  width: 100%; /* Cada div ocupa el ancho total del formulario */
}

.form-alameda label {
  display: block; /* Para que ocupe todo el ancho disponible */
  font-size: var(--letra-p);
  color: var(--gris-claro);
  margin-bottom: 0.5rem;
  font-family: var(--font-cuerpo);
  font-family: var(--font-cuerpo);
}

.form-alameda input[type="text"],
.form-alameda input[type="email"]{
  width: 100%; /* Input ocupa todo el ancho de su contenedor padre */
  padding: 0.5rem;
  border-radius: 0.5rem;
  border: none;
  box-sizing: border-box;
  font-size: var(--letra-p);
  font-family: var(--font-cuerpo);
}
.autorizo-checkbox {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  margin-top: 0.5rem;
}

.autorizo-checkbox input[type="checkbox"] {
  margin: 0; /* Elimina cualquier margen por defecto */
  width: auto; /* Asegúrate de que no se extienda horizontalmente */
  height: auto; /* Ajusta la altura al tamaño predeterminado */
  font-weight: bold;
}

.autorizo-checkbox label {
  font-size: var(--letra-p);
  color: var(--gris-claro);
  display: flex;
  align-items: center;
  line-height: 1.5rem; /* Para un alineamiento vertical más limpio */
  gap: 0.5rem; /* Espaciado entre los elementos internos */
  font-family: var(--font-cuerpo);
}


.form-alameda div{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: space-between; 

}
.btnNaranjaAlameda {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-claro);
  background: var(--naranja);
  border-radius: 1rem;
  margin: 0.5rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 100%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnNaranjaAlameda a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnNaranjaAlameda:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}
.btnNaranjaAlameda2 {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--gris-claro);
  background: var(--naranja);
  border-radius: 2rem;
  margin: 1rem;
  cursor: pointer;
  padding: 0.5rem;
  width: 20rem !important;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnNaranjaAlameda2 a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnNaranjaAlameda2:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

.proyecto-alameda{
  background-color: var(--naranja);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  width: 100%;

}
.proyecto-alameda h1{

  font-size: var(--letra-h1);
  text-align: center;
  padding: 1rem 0 1rem 0;
  margin: 0;
  -webkit-text-stroke: 2px var(--gris-claro); /* Añade un contorno negro */
  

}
.descubre-alameda{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: row;
  width: 100%;

}
.descubre-alameda-texto{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
}
.descubre-alameda p{
  font-family: var(--font-cuerpo);
  color: var(--azul-oscuro-alameda);
  width: 100%;
  font-size: var(--letra-h3);
  text-align: justify;
  padding: 3rem 3rem 0 3rem;
  

}
.info-y-avance-alameda{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: space-between;
  flex-direction: column;
  width: 60%;
  
}
.galerias-fotos-alameda {
  position: relative;
  width: 60%;
  max-width: 600px; /* Ajusta el tamaño según sea necesario */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  
}

.contenedor-foto {
  width: 100%;
  height: auto;
}

.contenedor-foto img {
  width: 100%;
  height: auto;
  display: block;
}

.btn-flecha {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: #fff;
  color: #333;
  font-size: var(--letra-boton);
  width: 3rem;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
  z-index: 10;
}

.btn-flecha.izquierda {
  left: 10px; /* Posición de la flecha izquierda */
}

.btn-flecha.derecha {
  right: 10px; /* Posición de la flecha derecha */
}

.espacios-alameda{
  background-color: var(--azul-oscuro-alameda);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 1rem;
}
.espacios-alameda h1{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  font-family: var(--font-titulos);
  margin:1rem;
  padding: 0;
  -webkit-text-stroke: 3px var(--gris-claro); /* Añade un contorno negro */
  font-weight: bold;
  line-height: 1;
  width: 30%;
  text-align: center;
}
.espacios-alameda p{
  font-size: var(--letra-h2);
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  width: 30%;
  text-align: justify;
  padding: 0 0.5rem 0 0.5rem;
}
.lineaVerticalNaranjaClaro{
  background-color: var(--naranja);
  width: 0.4rem;
  height: 60%;
  margin: 1rem;

}

.info-alameda {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 30%;
 }
.info-alameda-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:1rem;

}
.info-alameda-img img{
  margin:0.5rem;
  height: 3rem;
  

}
.info-alameda-texto{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  margin:0.5rem;
  
  

}
.info-alameda-texto p{
  color: var(--gris-claro);
  font-family: var(--font-cuerpo);
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 0.6rem 0 0.6rem 0;
  text-align: left;
  height: 3rem;
  line-height: 1;
}

.planta-alameda{
  background-color: var(--naranja);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 1rem;
}

.planta-alameda h1{
  font-family: var(--font-titulos);
  color: var(--azul-oscuro-alameda);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--azul-oscuro-alameda); /* Añade un contorno negro */
}
.planta-alameda img{
  width: 40%;
  text-align: center;
}
.comodidades-alameda{
  background-image: url('utils/alameda/Banner2.webp');
  background-size: cover; /* Ajusta la imagen para cubrir todo el contenedor */
  background-position: center; /* Centra la imagen */
  background-repeat: no-repeat; /* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 25rem;
  padding: 2rem;

}

.comodidades-alameda h1{
  font-family: var(--font-titulos);
  color: var(--azul-oscuro-alameda);
  font-size: var(--letra-h1);
  -webkit-text-stroke: 2px var(--azul-oscuro-alameda); /* Añade un contorno negro */
  width: 50%;
  line-height: 1;
}
.comodidades-alameda-lista{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  
  margin: 1rem;

}
.salon,.juegos,.sendero,.cancha{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;

}
.comodidades-alameda-lista img{
  margin:0.5rem 0.5rem 0 0;

}
.comodidades-alameda-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  font-family: var(--font-cuerpo);
  color: var(--azul-oscuro-alameda);
  margin: 0;
  padding: 0;


}
.ubicacion-alameda{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 5rem;
}
.ubicacion-alameda-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 40%;

}
.ubicacion-alameda-info h1{
  font-family: var(--font-titulos);
  font-size: var(--letra-h1);
  color: var(--azul-oscuro-alameda);
  -webkit-text-stroke: 3px var(--azul-oscuro-alameda); /* Añade un contorno negro */
  letter-spacing: 0.05em;
  margin:0.5rem;
  padding: 0;
}
.ubicacion-alameda-info h2{
  font-family: var(--font-titulos);
  font-size: var(--letra-h1);
  color: var(--azul-oscuro-alameda);
  letter-spacing: -0.02em;
  margin:0;
  padding: 0;
}

.ubicacion-alameda-maps{
  width: 50%;
}
.ubicacion-alameda-maps img{
  width: 100%;

}
.alameda-eleccion{
  background-color: var(--azul-oscuro-alameda);
  width: 100%;
  padding:2rem;
}
.alameda-eleccion h1{
  font-size: var(--letra-h1);
  color: var(--gris-claro);
  text-align: center;
  padding: 0;
  margin: 0;
  font-weight: normal;
}

.alameda-info{
  display: flex;
 flex-direction: row;
 align-items: center;
 justify-content: center;
 background-color: var(--naranja);
 width: 100%;
 padding: 1rem;
 gap:3rem;
}
.alameda-info p{
  color: var(--gris-claro);
  font-size: var(--letra-h3);
  font-weight: bold;
  text-align: center;
}
.alameda-info img{
  text-align: center;
}
.alameda-info div{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
}
.contactenos-alameda{
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  width: 100%;
  padding: 2rem 0 2rem 4rem;
  height: auto;
}
.contactenos-alameda-info{
  width: 100%;
  padding: 2rem 0 2rem 3rem;
}
.contactenos-alameda-info h1{
  font-family: var(--font-titulos);
  color: var(--azul-oscuro-alameda);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h1);
  font-weight: bold;
  letter-spacing: -0.01em;
  -webkit-text-stroke: 1px var(--azul-oscuro-alameda); /* Añade un contorno negro */
  width: 30rem;
}
.contactenos-alameda-info p{
  font-family: var(--font-titulos);
  color: var(--azul-oscuro-alameda);
  margin: 0;
  padding: 0;
  font-size: var(--letra-h3);
  width: 100%;
}

.btnNaranjaAlamedaContacto {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: auto;
  color: var(--azul-oscuro-alameda);
  background: var(--naranja);
  border-radius: 2rem;
  margin: 2rem 0 2rem 0;
  cursor: pointer;
  padding: 0.5rem;
  width: 30%;
  font-size: var(--letra-h3); /* Ajusta el tamaño de la letra */
  font-family: var(--font-cuerpo, Arial, sans-serif); /* Cambia la fuente */
  border: none; /* Elimina bordes */
  outline: none; /* Elimina contornos */
  box-shadow: none; /* Elimina sombras */
  transition: background-color 0.3s ease; /* Transición para hover */
}
.btnNaranjaAlamedaContacto a {
  color: inherit; /* Hereda el color del contenedor */
  text-decoration: none; /* Sin subrayado */
  width: 100%; /* Ocupar todo el ancho del botón */
  height: 100%; /* Ocupar toda la altura del botón */
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
}

.btnNaranjaAlamedaContacto:hover {
  background: var(--gris-medio); /* Color más claro al pasar el cursor */
}

 

/*footbar*/
.footbar {
  background-color: var(--gris-claro); /* Color por defecto */
  height: 11rem;
}
.footer-hidden {
  display: none;
}
.hide-footer {
  display: none;
}
.footbar.footer-hidden {
  background-color: var(--gris-oscuro); /* Color específico para Conócenos */
  color: var(--gris-claro);
}
.footbar.footer-hidden p {
  color: var(--gris-claro);
}

.footbar {
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: space-between;  
  padding: 4rem;


}
.footbar-info{
  display: flex; 
  flex-direction: column;
  align-items: flex-start; 
  justify-content: space-between; 
  width: 50%;
  
}
.footbar-info a, 
.btnNaranjaNavbar a,.btnNaranjaNavbarMovil a, .btnNaranjaFootbar a, .btnNaranja a,button, .btnGrisSauces2 a,
.btnNaranjaPiamonte2 a,.btnNaranjaSantaClara2 a, .btnNaranjaAcacias2 a,.btnNaranjaAlameda2 a, .btnGrisClaro a  {
  text-decoration: none; /* Elimina el subrayado */
  color: inherit; /* Usa el color del texto padre o define un color específico */
}

.instagram, .email, .ubicacion{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: center;
  margin:0;
  padding: 0;
  text-align: left;
  
}
.instagram-icon, .email-icon, .ubicacion-icon{
  height: 1.6rem;
  padding-right: 0.5rem;
  
}
.footbar-info p, .contactanos p{
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  padding: 0;
  margin: 0.5rem;
  
  
}
.contactanos{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  text-align: center;
  width: 20%;
  

}

.lineaVerticalFootbar{
  background-color: var(--naranja);
  width: 0.4rem;
  height: 10rem;
  margin: 0 0 0 25%;
}
/* Estilo base para pantallas grandes (escondemos el ícono de menú) */
.menu-icon,.menu-content ul {
  display: none; /* Inicialmente ocultamos el ícono de menú */
}

/*ScrollToTop*/
.scroll-to-top {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: var(--naranja);
  color: white;
  border: none;
  border-radius: 50%;
  font-size: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s, transform 0.3s;
  z-index: 1000;
}

.scroll-to-top:hover {
  background-color: var(--naranja);
}

.scroll-to-top:active {
  transform: scale(0.9);
}

/*Constructor*/
.constructor-page{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 60rem;
  width: 100%;
}

.constructor-page-title{
  
  display: flex; 
  flex-direction: column;
  align-items: flex-end; 
  margin:0;
  padding: 0;
  width: 50rem;
  height: 10rem;
  position: relative;
  
}

.constructor-page-title img{
  width: 5rem;
  height: auto;
  margin: 0 2rem 0 0;
}
.constructor-page-title h2{
  font-size: var(--letra-h2);
  background-color: var(--naranja);
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  padding: 0.8rem;
  border-radius: 0 2.5rem 2.5rem 0;
  width: 50rem;
  text-align: right;
}
.constructor-page-title h2 strong {
  font-weight: 900; /* O usa 800 si 900 es demasiado grueso */
  -webkit-text-stroke: 1.5px var(--gris-oscuro); /* Añade un contorno negro */
}


#elementoC1 {
  position: absolute;
  top: -25%;
  transform: translateY(-10%);
  z-index: 3; /* Mayor valor = más arriba */
}

#elementoC2 {
  position: absolute;
  top: 5%;
  transform: translateY(-10%);
  z-index: 2; /* Debajo de elemento1 */
}

.constructor-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: justify;
}
.mensaje-constructor{
  width: 50%;
  padding: 0 3rem 0 3rem;
}
.mensaje-constructor h2{
  font-size: var(--letra-h2);
  color: var(--naranja);
  font-family: var(--font-titulos);
  text-align: left;
  -webkit-text-stroke: 2.5px var(--naranja);
}
.mensaje-constructor p{
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  
}
/*Vivienda*/
.vivienda-page{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: 60rem;
  width: 100%;
}

.vivienda-page-title{
  
  display: flex; 
  flex-direction: column;
  align-items: flex-end; 
  margin:0;
  padding: 0;
  width: 50rem;
  height: 10rem;
  position: relative;
  
}

.vivienda-page-title img{
  width: 4rem;
  height: auto;
  margin: 0 2rem 0 0;
}
.vivienda-page-title h2{
  font-size: var(--letra-h2);
  background-color: var(--naranja);
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  padding: 0.8rem;
  border-radius: 0 2.5rem 2.5rem 0;
  width: 50rem;
  text-align: right;
}
.vivienda-page-title h2 strong {
  font-weight: 900; /* O usa 800 si 900 es demasiado grueso */
  -webkit-text-stroke: 1.5px var(--gris-oscuro); /* Añade un contorno negro */
}


#elementoV1 {
  position: absolute;
  top: -25%;
  transform: translateY(-10%);
  z-index: 3; /* Mayor valor = más arriba */
}

#elementoV2 {
  position: absolute;
  top: 5%;
  transform: translateY(-10%);
  z-index: 2; /* Debajo de elemento1 */
}

.vivienda-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: justify;
}
.mensaje-vivienda{
  width: 50%;
  padding: 0 3rem 0 3rem;
}
.mensaje-vivienda h2{
  font-size: var(--letra-h2);
  color: var(--naranja);
  font-family: var(--font-titulos);
  text-align: left;
  -webkit-text-stroke: 2.5px var(--naranja);
}
.mensaje-vivienda p{
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  
}

/*PQR*/
.pqr-page{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  margin: 4rem 1rem 1rem 1rem;
}



.pqr-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  text-align: justify;
}
.mensaje-pqr{
  width: 50%;
  padding: 0 3rem 0 3rem;
}
.mensaje-pqr h1{
  font-size: var(--letra-h1);
  color: var(--naranja);
  font-family: var(--font-titulos);
  text-align: left;
  -webkit-text-stroke: 2.5px var(--naranja);
}
.mensaje-pqr h2{
  font-size: 2rem;
  color: var(--gris-oscuro);
  font-family: var(--font-titulos);
  text-align: left;
  
}
.mensaje-pqr p{
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  
}

@media screen and (max-width: 1060px) {
 /* Navbar contenedor */
/*
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 1rem;
  position: relative;
  font-size: var(--letra-p);

}*/
.menu-icon,.navbar-pc {
  display: none; /* Inicialmente ocultamos el ícono de menú */
}

.navbar {
  /*position: fixed;*/
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.5rem;
  font-size: var(--letra-p);
}
/* Menú desplegado */
.menu-content {
  position: fixed;
  top: 0;
  right: -100%; /* Coloca el menú fuera de la pantalla */
  width: 50%; /* Ancho del menú */
  height: 100vh;
  background-color: var(--naranja);
  box-shadow: 2px 0 5px rgba(0, 0, 0, 0.5);
  transition: right 0.3s ease; /* Transición suave para 'right' */
  z-index: 5; /* Asegura que el menú se muestre por encima de otros elementos */
  padding: 0 0.5rem 0 0;
  margin: 0;
  
}
.menu-content > ul > li > a {
  position: relative;  /* Asegura que el pseudo-elemento se posicione relativo al enlace */
  z-index: 4 ; /* Mantiene el texto por encima del fondo */
}

.menu-content > ul > li > a:hover::after {
  content: '';
  position: absolute ;
  left: 0;
  top: 100%; /* Asegura que el fondo cubra todo el enlace */
  width: 8rem !important;
  height: 0.01rem; /* Hace que el fondo cubra todo el área del enlace */
  background-color: var(--gris-claro);
  border-radius: 0.5rem;
  transition: background-color 0.3s ease; /* Animación suave para el fondo */
  z-index: 0 ; /* Envía el fondo detrás del texto */
}


/* Al cargar la página, el enlace de "Home" tiene la línea por defecto */
.menu-content li a.active::after {
  width: 100%; /* Muestra la línea morada completamente debajo de "Home" */
}

/* Menú activo: cuando la clase 'active' está presente */
.menu-content.active {
  right: 0; /* Muestra el menú al moverlo a la derecha de la pantalla */
  

}
.menu-content li.active > ul {
  display: block; /* Muestra el submenú cuando está activo */
}



/* Muestra la lista cuando se pasa el ratón por encima */

.menu-content li:hover > ul {
  display: block; 
}

.proxLanzamientos a{
  line-height: 1;
  margin: 1rem 0 0 0 !important;

}
.servicioClienteLi a{
  line-height: 1;
  margin: 1rem 0 0 0 !important;
}

.menu-icon {
  display: block;
  cursor: pointer;
  position: fixed; /* Fija el ícono en la parte superior */
  top: 1rem; /* Ajusta la distancia desde la parte superior */
  right: 1rem; /* Coloca el ícono en el lado derecho */
  z-index: 6; /* Asegura que el ícono esté por encima del menú */
}

.union-item {
  
  padding: 0 !important;
  margin: 0 !important;
}
/*
.bolivar-item li{
  margin:0 0 0 1rem !important;
}
.sucre-item li{
  margin:0 0 0 2rem !important;

}*/


/* Menu 1 (Navbar) */
.menu-content {
  padding: 4rem 0 0 1rem;
  

}
/* Todos los menu */
.menu-content ul {
  display: block;
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
  line-height: 2.5;
  padding: 0.5rem;
 


}
/*Proyectos*/
.menu-content ul ul{
  margin:0 0 1rem 0;
}


/* Menu 2 (Ciudades) */
nav ul ul {
  display: none; /* Ocultamos por defecto */
  position: relative; /* Usamos posición absoluta para controlar su ubicación */
  background: var(--gris-claro);
  list-style: none;
  left: -65%; /* Movemos hacia la izquierda más allá del contenedor */
  top: 0%; /* Movemos hacia abajo completamente respecto al elemento padre */
  transform: translateY(40px); /* Ajustamos más hacia abajo */
  color: var(--gris-oscuro);
  z-index: 100; 
  padding: 0 1rem 0 1rem;
  border-radius: 0.5rem;
  white-space: nowrap;
  

  
}

/* Menu 3 (Proyectos) */
nav ul ul ul {
  display: none; /* Ocultamos por defecto */
  position: relative; 
  background: var(--gris-oscuro);
  margin: 0 ;
  list-style: none;
  /*left: 0%; /* Movemos hacia la izquierda más allá del contenedor */
  top: 0; /* Movemos hacia abajo completamente respecto al elemento padre */
  transform: translateY(35px); /* Ajustamos más hacia abajo */
  color: var(--gris-claro) !important;
  z-index: 100; 
  padding: 0 ;
  
}
.union-item ul{
  left: -40%;
}
.bolivar-item ul{
  left: -30%;
}
.sucre-item ul{
  left: -25%;
}
/* Menu 3 (Proyectos) */
.menu-content ul ul ul li a{
  border: 1px solid var(--gris-claro);
  margin: 0.1rem !important;
  padding: 0 0.5rem 0 0.5rem !important;
  border-radius: 0.5rem;
  text-align: center;

}
/* Menu 2 (Ciudades)*/
.menu-content ul ul{
  margin: 0 0 2rem 0 !important;
}

nav ul li:hover > ul {
  display: block;
  margin: 0 0 3rem 0;
}


nav ul ul li {
  padding: 0;
  margin:0;
  width: 100%;
  white-space: nowrap; 
  
}

nav ul ul li a {
  text-decoration: none;
  text-align: left;
  width: 100%;
  margin: 0;
  padding: 0;
  max-width: 7rem;
  white-space: nowrap; 
  
  
  
}
nav ul li a{
  text-align: left;
  padding: 0;
  width: 100%;
  
  

}

.menu-content ul li {
  margin: 0;
  padding: 0;
  height: auto;
  width: auto;
  display: flex;
 align-items: flex-start;
 justify-content: flex-start;  
  
}

.menu-content .navbar-menu li a {
  text-decoration: none;
  font-size: var(--letra-pp);
  color:var(--gris-oscuro);
  margin: 0;
  padding: 0;
  width: 7rem;
  font-family: var(--font-titulos);
  
}
/*menu 3 proyectos*/
.menu-content .navbar-menu ul li a {
  text-decoration: none;
  font-size: var(--letra-pp);
  color:var(--gris-claro);
  padding: 0;
  font-family: var(--font-titulos);
  margin:0;
  
}
/*ciudades*/
.menu-content .navbar-menu {
  font-size: var(--letra-pp);
}

/* Estilo para el hover - línea debajo del enlace */



/* Al cargar la página, el enlace de "Home" tiene la línea por defecto */
.menu-content ul li a.active::after {
  width: 100%; /* Muestra la línea completamente debajo de "Home" */


}

.navbar-pc ul{
  display: none;
}

.logo {
  height: 4rem; 
  width: auto;
  
}

.btnNaranjaNavbarMovil{
  display: flex; 
  width: auto;  
  height: auto ; 
  background: var(--gris-claro);
  border-radius: 2rem;
  margin:2rem 1rem 1rem 0;
  text-align: left;
  cursor: pointer; 
  padding: 0 0.5rem 0 0.5rem;
  
  
}
.btnNaranjaNavbarMovil a{
  font-size: var(--letra-p) !important;
  font-weight: bold;
  color: var(--gris-oscuro);

  
}


.btnNaranjaNavbar{
  display: none;
  
}

.menu-icon img{
  width: 2rem;
  height: auto;
}


/*INICIO*/


.BannerHome{
  width: 100%; 
  margin-top: 1rem;
  padding: 0 0.5rem 0 0.5rem;
}
.aliados{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  padding: 0 1rem 0 1rem;
  width: 100%;
  
}
.HomeCircle {
  padding: 0;
  margin: 0 0 1rem 0;
  width: 60%;
  
}
.aliadosInfo{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  margin:0;
  width: 100%;
  
  
}
.aliadosInfo > h2 {
  font-size: var(--letra-h3);
  color:var(--gris-oscuro);
  font-family: var(--font-titulos);
  font-weight: 100;
  text-align: left;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  -webkit-text-stroke: 0.8px var(--gris-oscuro); /* Añade un contorno negro */
  width: 90%;
  margin: 0 1rem 0 3rem;
}
.aliadosInfo p {
  font-size: var(--letra-p);
  color:var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  text-align: justify;
  
}
.cuadro-gris-btn{

  width: 80%;
  margin: 1rem 0 1rem 0;
}

.cuadro-gris-btn h3{
  color:var(--gris-claro);
  font-size:var(--letra-p);
  font-family: var(--font-cuerpo);
  padding: 0;

}
.btnGrisClaro {

  width: 70%;
  margin: 0;
  
}

.constructor-vivienda{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  gap:13rem;
  margin: 0;
  width: 90%;
  height: 50rem;
  
}
.constructor, .vivienda{
  
  display: flex; 
  flex-direction: column;
  align-items: center; 
  margin:2rem 0 0 0 ;
  padding: 0;
  width: 90%;
  position: relative;

  
}

.constructor img{
  width: 4rem;  
  height: auto;
}
.vivienda img{
  width: 3rem;
  height: auto;
}
.constructor h3, .vivienda h3{
  
  font-size: var(--letra-h3);
  background-color: var(--naranja);
  font-family: var(--font-titulos);
  color: var(--gris-oscuro);
  padding: 0.8rem;
  border-radius: 2.5rem;
  width: 90%;
  

  
}

.constructor p, .vivienda p{
  
  font-size: var(--letra-p) ;
  padding: 3rem 1.5rem 1rem 1.5rem;
  max-width: 20rem;
  border-radius: 2rem;
  width: 20rem;
  /*min-height: 20rem;
  width: 25rem;
  text-align: justify;*/
  
}

#elemento1 {
  position: absolute;
  top: -35%;
  transform: translateY(-10%);
  z-index: 3; /* Mayor valor = más arriba */
}

#elemento2 {
  position: absolute;
  top: -5%;
  transform: translateY(-10%);
  z-index: 2; /* Debajo de elemento1 */
}

#elemento3 {
  position: absolute;
  top: 0%;
  z-index: 1; /* Debajo de elemento2 */
}
.constructor-vivienda-form, .constructor-vivienda-form-conocenos{
  
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: space-around; 
  width: 100%;
  
}
.mensaje-constructor-vivienda, .mensaje-constructor-vivienda-conocenos{
  width: 90%;
  margin: 1rem;
  text-align: left;
}
.mensaje-constructor-vivienda h2{
  font-size: var(--letra-h3);
  text-align: left;
  -webkit-text-stroke: 1px var(--gris-claro); /* Añade un contorno negro */
  
}
.mensaje-constructor-vivienda h3{
  font-size: var(--letra-p);
  font-weight: 100;

}
.form-inicio, .form-conocenos{
  width: 90%;
  margin: 0 0 1rem 0;
  
  
}
.form-inicio label, .form-conocenos label {
  font-size: var(--letra-pp);
  margin-bottom: 0.5rem;

}

.form-inicio input[type="text"],
.form-inicio input[type="email"],
.form-conocenos input[type="text"],
.form-conocenos input[type="email"]{
  width: 100%; /* Input ocupa todo el ancho de su contenedor padre */
  padding: 0.5rem;
  border-radius: 0.5rem;
  font-size: var(--letra-pp);
}
.custom-select {
  width: 100%;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #ccc;
  font-size: var(--letra-pp);

}

.custom-select:focus {
  border-color: var(--naranja);
  outline: none;
}

.custom-select option {
  padding: 10px;
  font-family: var(--font-cuerpo);
}
.autorizo-checkbox-inicio {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  gap: 0.5rem; /* Espacio entre el checkbox y el texto */
  margin-top: 0.5rem;
  text-align: left;
}


.autorizo-checkbox-inicio label {
  font-size: var(--letra-pp);
  display: flex;
  align-items: center;
  text-align: left;
  line-height: 1.5rem; /* Para un alineamiento vertical más limpio */
  gap: 0.5rem; /* Espaciado entre los elementos internos */
}
.proyectos-list{
  padding: 1rem;
}
.LogosProyectosDiv{
  margin: 1rem 0 0 0;
}

.LogosProyectos{
  
  margin:0;
  border-radius: 1rem;
  
}
.proyectos-list{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  text-align: left;
}


.proyectos-list-info-text h1{

  font-size: var(--letra-h3); 
  margin: 0.5rem 0 0 0;
  padding: 0;
}
.proyectos-list-info-text p{
  font-size: var(--letra-pp);
}

.lineaVertical{
  background-color: var(--naranja);
  width: 0.25rem;
  margin: 0.5rem;
}


.clientes{
  
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center; 
  text-align: left;
  width: 100%;
  padding: 0 1rem 0 1rem;
}
.clientes-logo{
  display: flex; 
  flex-direction: column;
  align-items:flex-start; 
  justify-content: left; 
  width: 100%;
}
.clientes-logo img{
  width: 90%;
}
.clientes-logo h1{
  font-size: var(--letra-h3);
  font-weight: bold;
  color: var(--gris-oscuro);
}
/* Estilo del círculo */
.circleDarkGrey {
  width: 1rem;  /* Ancho del círculo */
  height: 1rem;  /* Alto del círculo (igual al ancho para hacer un círculo) */
  border-radius: 50%;  /* Hace que el div sea un círculo */
}
.ovalGrey {
  width: 4rem;  /* Ajusta el ancho del óvalo */
  height: 1rem;  /* Ajusta la altura del óvalo */
  border-radius: 3rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  padding-right: 0.3rem; /* Espacio a la derecha entre el círculo y el borde del óvalo */
  margin-right: 0.5rem;
}
.clientes-info{
  margin: 1rem;
}
.clientes-info li{
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  margin-bottom: 1rem;
}
.grua{
  margin: 2rem;
}


/*CONOCENOS*/
.conocenos{
  display: flex; 
  flex-direction: column;
  align-items: center; 
}

.somos{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: space-evenly;  
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;

}
.ImagenSomos{
  width: 60%;
}
.lineaVerticalGris{
  display: none;
}
.somos p{
  font-size: var(--letra-p);
  width: 100%;
}
.valores{

  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: space-evenly;  
  width: 100%;
  padding: 1rem 0.5rem 1rem 0.5rem;
}
.circleOrange {
  width: 3rem;  /* Ancho del círculo */
  height: 3rem;  /* Alto del círculo (igual al ancho para hacer un círculo) */
  border-radius: 3rem;  /* Hace que el div sea un círculo */
}
.ovalGreyConocenos {
  width: 20rem;  /* Ajusta el ancho del óvalo */
  height: 3rem;  /* Ajusta la altura del óvalo */
  border-radius: 3rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  padding-right: 0rem; /* Espacio a la derecha entre el círculo y el borde del óvalo */
  margin-right: 0.5rem;
}
.ovalGreyConocenos h1{
  margin: 0 0.35rem 0 0.5rem;
  padding: 0;
  font-size: var(--letra-h3);
  width: 80%;
}
.valores-list{
  margin: 1.5rem 0 0 0;
  width: 100%;
  height: auto;

}
.ovalGreyValores{
  
  border-radius: 3rem;  /* Hace que los bordes sean redondeados, formando un óvalo */
  margin: 0 1rem 0 0;
  
}
.ovalGreyValores h1{
  margin: 0;
  padding: 0.2rem 0.25rem 0.2rem 0.25rem;
  font-size: var(--letra-p);
}
.valores-list-texto p{
  font-size: var(--letra-p);
}
.valores-list-texto{

  margin:0.5rem
}


.mision-vision{
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: center;
  text-align: justify;  
  padding: 1rem 0.5rem 1rem 0.5rem;
  width: 100%;
  

}
.mision-vision-detalle{
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: center;
  text-align: center;
  width: 100%;

}
.mision-vision-detalle h1{
  font-size: var(--letra-h3);
  padding: 1.5rem 0 0 0;
  margin: 0;
}
.mision-vision-detalle p{
  font-size: var(--letra-p);
  padding: 1rem;
  margin: 0;
  
}
.mision-texto h1, .mision-text p{
  margin: 0;
  padding: 0;
}
.logo-mision, .logo-vision{
  min-height: 4rem;
  width: 4rem;
  padding: 0;
  margin: 0;
  

}
.logo-mision img, .logo-vision img{
  height: 3rem;
  padding: 0;
  margin: 0;

}
.mision, .vision{
  width: 100%;
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content:center;
  text-align: center;
  margin: 0;
}
.mision-texto,.vision-texto{
  width:90%;
  padding: 0.5rem 0 0 0;
  height: 20rem;
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content:center;
  text-align: center;
  
  
}
.mision-vision-contactanos{
  width: 100%;

}

.mision-vision-contactanos h1{
  font-size: var(--letra-h3);

  
}

.btnNaranja{
  display: inline-block; 
  width: auto;  
  height: auto ; 
  color: var(--gris-oscuro);
  background: var(--naranja);
  border-radius: 2rem;
  margin:0 0 1rem 0;
  text-align: center;
  cursor: pointer; 
  padding: 0 1rem 0 1rem;
  
  
}
.btnNaranja a{
  font-size: var(--letra-h2) !important;
  font-weight: bold;
  
}
/*Sauces*/
.sauces{

  padding: 0;
  margin: 0;

}

.banner-sauces{

  background-image: url('utils/sauces/Banner.webp');
  background-size: auto 200px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;
  height: auto; /* Ajusta la altura según lo necesites */
  width: 100%; /* Ajusta el ancho si es necesario */
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.banner-sauces-info{
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: space-between; 
  padding: 0.5rem;
  width: 100%;
  height: 30rem;
}


.banner-sauces-info h1{
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  width: 100%;
  text-align: center;
 

}
.banner-sauces-info h2{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */


}
.banner-sauces-info h3{
  width: 100%;
  font-size: var(--letra-h3);
  padding: 1rem;
  color: var(--gris-oscuro);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  text-align: center;

}


.form-sauces {
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem; /* Ancho total de la tarjeta del formulario */
  margin: 0 0 1rem 0;
}

.btnGrisSauces a {
  font-size: var(--letra-p);
}
.btnGrisSauces2{
  width: 15rem !important;
}
.btnGrisSaucesContacto {
  width: 100%;
}
.proyecto-sauces h1{

  font-size: var(--letra-h3);

}
.descubre-sauces{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: column;
  width: 100%;

}
.descubre-sauces p{
 
  width: 100%;
  font-size: var(--letra-p);
  padding: 0.8rem;
  

}
.info-y-avance-sauces{
  width: 90%;
  
}
.galerias-fotos-sauces {
  position: relative;
  width: 80%;
  margin: 0 0 1rem;
  
}


.btn-flecha {

  font-size: var(--letra-p);
  width: 2rem;
  height: 2rem;

}

.espacios-sauces{
  
  flex-direction: column;
  height: auto;
  padding: 1rem 0 0 0;
}
.espacios-sauces h1{
  font-size: var(--letra-h2);
  margin:0;
  padding: 0;
  -webkit-text-stroke: 1px var(--gris-claro); /* Añade un contorno negro */
  width: 100%;
  text-align: center;
}
.espacios-sauces p{
  font-size: var(--letra-h3);
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;
  margin:0;
}
.lineaVerticalVerde{
  width: 90%;
  height: 0.4rem;
  margin: 1rem;

}

.info-sauces {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
 }
.info-sauces-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:0 1rem 0 1rem;

}
.info-sauces-img img{
  margin:1.5rem;
  height: 3.5rem;
  

}
.info-sauces-texto{
  
  flex-direction: column;

}
.info-sauces-texto p{
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
  text-align: left;
  height: 5rem;
}

.planta-sauces{

  flex-direction: column;
  padding: 0.5rem;
}

.planta-sauces h1{

  font-size: var(--letra-h3);
  
}

.comodidades-sauces{
  background-image: url('utils/sauces/Banner2.webp');
  background-size: auto 150px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;/* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0.5rem;

}
.comodidades-sauces h1{

  font-size: var(--letra-h3);
  width: 100%;
  margin:1.5rem 0 1rem 0;
  -webkit-text-stroke: 1px var(--gris-claro);
  
}

.comodidades-sauces-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);


}
.ubicacion-sauces{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
.ubicacion-sauces-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

}
.ubicacion-sauces-info h1{
  
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.1em;
  margin:0.5rem;
  padding: 0;
}
.ubicacion-sauces-info h2{
  font-size: var(--letra-h3);
  letter-spacing: -0.05em;
  margin:0;
  padding: 0;
}
.ubicacion-sauces-info img{
  width: 3rem;
}
.ubicacion-sauces-maps{
  width: 90%;
  margin:0.5rem
}
.ubicacion-sauces-maps img{
  width: 100%;

}

.sauces-eleccion h1,.sauces-eleccion h2{
  font-size: var(--letra-h3);
}

.sauces-info{
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}


.contactenos-sauces{

  flex-direction: column;
  padding: 0;
  align-items: center;
  justify-content: center;
  margin:0;
  
}
.contactenos-sauces-info{
  padding: 0.5rem;

}
.contactenos-sauces-info h1{

  font-size: var(--letra-h3);
  letter-spacing: -0.01em;
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  width: 100%;
  padding: 0;
  margin: 0;
}
.contactenos-sauces-info p{

  font-size: var(--letra-h3);
  width: 100%;
  margin:0.5rem;
}

/*piamonte*/

.piamonte{

  padding: 0;
  margin: 0;

}

.banner-piamonte{

  background-image: url('utils/piamonte/Banner.webp');
  background-size: auto 200px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;
  height: auto; /* Ajusta la altura según lo necesites */
  width: 100%; /* Ajusta el ancho si es necesario */
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.banner-piamonte-info{
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: space-between; 
  padding: 0.5rem;
  width: 100%;
  height: 35rem;
}

.banner-piamonte-info h1{
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  width: 100%;
  padding: 1rem;
}
.banner-piamonte-info h2{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  

}
.banner-piamonte-info h3{
  width: 100%;
  font-size: var(--letra-h3);
  padding: 1rem;
  color: var(--gris-oscuro);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */

}


.form-piamonte {
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem; /* Ancho total de la tarjeta del formulario */
  margin: 0 0 1rem 0;
}

.btnNaranjaPiamonte a {
  font-size: var(--letra-p);
}
.btnNaranjaPiamonte2{
  width: 15rem !important;
}
.btnVerdePiamonteContacto {
  width: 100%;
}
.proyecto-piamonte h1{

  font-size: var(--letra-h3);

}
.descubre-piamonte{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: column;
  width: 100%;

}
.descubre-piamonte p{
 
  width: 100%;
  font-size: var(--letra-p);
  padding: 0.8rem;
  

}
.info-y-avance-piamonte{
  width: 90%;
  
}
.galerias-fotos-piamonte {
  position: relative;
  width: 80%;
  margin: 0 0 1rem;
  
}


.btn-flecha {

  font-size: var(--letra-p);
  width: 2rem;
  height: 2rem;

}

.espacios-piamonte{
  
  flex-direction: column;
  height: auto;
  padding: 1rem 0 0 0;
}
.espacios-piamonte h1{
  font-size: var(--letra-h2);
  margin:0;
  padding: 0;
  -webkit-text-stroke: 1px var(--gris-claro); /* Añade un contorno negro */
  width: 100%;
  text-align: center;
}
.espacios-piamonte p{
  font-size: var(--letra-h3);
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;
  margin:0;
}
.lineaVerticalVerdeOscuro{
  width: 90%;
  height: 0.4rem;
  margin: 1rem;

}


.info-piamonte {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
 }
.info-piamonte-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:0 1rem 0 1rem;

}
.info-piamonte-img img{
  margin:1.5rem;
  height: 3.5rem;
  

}
.info-piamonte-texto{
  
  flex-direction: column;

}
.info-piamonte-texto p{
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
  text-align: left;
  height: 5rem;
}

.planta-piamonte{

  flex-direction: column;
  padding: 0.5rem;
}

.planta-piamonte h1{

  font-size: var(--letra-h3);
  
}

.comodidades-piamonte{
  background-size: auto 150px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;/* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0.5rem;
  

}
.comodidades-piamonte h1{

  font-size: var(--letra-h3);
  width: 100%;
  -webkit-text-stroke: 0.5px var(--gris-oscuro);
  margin:1.5rem 0 1rem 0;
  
}

.comodidades-piamonte-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);


}
.ubicacion-piamonte{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
.ubicacion-piamonte-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

}
.ubicacion-piamonte-info h1{
  
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.1em;
  margin:0.5rem;
  padding: 0;
}
.ubicacion-piamonte-info h2{
  font-size: var(--letra-h3);
  letter-spacing: -0.05em;
  margin:0;
  padding: 0;
}
.ubicacion-piamonte-info img{
  width: 3rem;
}
.ubicacion-piamonte-maps{
  width: 90%;
  margin:0.5rem
}
.ubicacion-piamonte-maps img{
  width: 100%;

}

.piamonte-eleccion h1,h2{
  font-size: var(--letra-h3);
}

.piamonte-info{
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}


.contactenos-piamonte{

  flex-direction: column;
  padding: 0.5rem 0 0.5rem 0.5rem;
  
}
.contactenos-piamonte-info{
  padding: 0.5rem;

}
.contactenos-piamonte-info h1{

  font-size: var(--letra-h3);
  letter-spacing: 0.1em;
  width: 100%;
  text-align: center;
  -webkit-text-stroke: 1px var(--verde-oscuro-piamonte);
  
}
.contactenos-piamonte-info p{

  font-size: var(--letra-h3);
  width: 100%;
  margin:0.5rem;
}

/*Santa Clara*/

.santaclara{

  padding: 0;
  margin: 0;

}

.banner-santaclara{

  background-image: url('utils/santaclara/Banner.webp');
  background-size: auto 200px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;
  height: auto; /* Ajusta la altura según lo necesites */
  width: 100%; /* Ajusta el ancho si es necesario */
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.banner-santaclara-info{
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: space-between; 
  padding: 0.5rem;
  width: 100%;
  height: 35rem;
}

.banner-santaclara-info h1{
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  width: 100%;
  padding: 1rem;
}
.banner-santaclara-info h2{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  

}
.banner-santaclara-info h3{
  width: 100%;
  font-size: var(--letra-h3);
  padding: 1rem;
  color: var(--gris-oscuro);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */

}


.form-santaclara {
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem; /* Ancho total de la tarjeta del formulario */
  margin: 0 0 1rem 0;
}

.btnNaranjaSantaClara a {
  font-size: var(--letra-p);
}
.btnNaranjaSantaClara2{
  width: 15rem !important;
}
.btnVerdeSantaClaraContacto {
  width: 100%;
}
.proyecto-santaclara h1{

  font-size: var(--letra-h3);

}
.descubre-santaclara{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: column;
  width: 100%;

}
.descubre-santaclara p{
 
  width: 100%;
  font-size: var(--letra-p);
  padding: 0.8rem;
  

}
.info-y-avance-santaclara{
  width: 90%;
  
}
.galerias-fotos-santaclara {
  position: relative;
  width: 80%;
  margin: 0 0 1rem;
  
}


.btn-flecha {

  font-size: var(--letra-p);
  width: 2rem;
  height: 2rem;

}

.espacios-santaclara{
  
  flex-direction: column;
  height: auto;
  padding: 1rem 0 0 0;
}
.espacios-santaclara h1{
  font-size: var(--letra-h2);
  margin:0;
  padding: 0;
  -webkit-text-stroke: 1px var(--gris-claro); /* Añade un contorno negro */
  width: 100%;
  text-align: center;
}
.espacios-santaclara p{
  font-size: var(--letra-h3);
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;
  margin:0;
}
.lineaVerticalVerdeOscuro{
  width: 90%;
  height: 0.4rem;
  margin: 1rem;

}


.info-santaclara {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
 }
.info-santaclara-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:0 1rem 0 1rem;

}
.info-santaclara-img img{
  margin:1.5rem;
  height: 3.5rem;
  

}
.info-santaclara-texto{
  
  flex-direction: column;

}
.info-santaclara-texto p{
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 0.5rem 0 0.5rem 0;
  text-align: left;
  height: 5rem;
}

.planta-santaclara{

  flex-direction: column;
  padding: 0.5rem;
}

.planta-santaclara h1{

  font-size: var(--letra-h3);
  
}

.comodidades-santaclara{
  background-size: auto 150px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;/* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0.5rem;
  

}
.comodidades-santaclara h1{

  font-size: var(--letra-h3);
  width: 100%;
  -webkit-text-stroke: 0.5px var(--gris-oscuro);
  margin:1.5rem 0 1rem 0;
  
}

.comodidades-santaclara-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);


}
.ubicacion-santaclara{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
.ubicacion-santaclara-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

}
.ubicacion-santaclara-info h1{
  
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.1em;
  margin:0.5rem;
  padding: 0;
}
.ubicacion-santaclara-info h2{
  font-size: var(--letra-h3);
  letter-spacing: -0.05em;
  margin:0;
  padding: 0;
}
.ubicacion-santaclara-info img{
  width: 3rem;
}
.ubicacion-santaclara-maps{
  width: 90%;
  margin:0.5rem
}
.ubicacion-santaclara-maps img{
  width: 100%;

}

.santaclara-eleccion h1,h2{
  font-size: var(--letra-h3);
}

.santaclara-info{
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}


.contactenos-santaclara{

  flex-direction: column;
  padding: 0.5rem 0 0.5rem 0.5rem;
  
}
.contactenos-santaclara-info{
  padding: 0.5rem;

}
.contactenos-santaclara-info h1{

  font-size: var(--letra-h3);
  letter-spacing: 0.1em;
  width: 100%;
  text-align: center;
  -webkit-text-stroke: 1px var(--verde-oscuro-santaclara);
  
}
.contactenos-santaclara-info p{

  font-size: var(--letra-h3);
  width: 100%;
  margin:0.5rem;
}

/*Acacias*/

.acacias{

  padding: 0;
  margin: 0;

}

.banner-acacias{

  background-image: url('utils/acacias/Banner.webp');
  background-size: auto 200px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;
  height: auto; /* Ajusta la altura según lo necesites */
  width: 100%; /* Ajusta el ancho si es necesario */
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.banner-acacias-info{
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: space-between; 
  padding: 0.5rem;
  width: 100%;
  height: 35rem;
}

.logoAcacias{
  height: 10rem;
  margin: 1rem 0 0 0;
}

.banner-acacias-info h1{
  font-size: var(--letra-h2);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 3px var(--gris-oscuro); /* Añade un contorno negro */
  width: 100%;
  padding: 1rem;
}
.banner-acacias-info h2{
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  

}
.banner-acacias-info h3{
  width: 100%;
  font-size: var(--letra-h3);
  padding: 1rem;
  color: var(--gris-oscuro);
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */

}


.form-acacias {
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem; /* Ancho total de la tarjeta del formulario */
  margin: 0 0 1rem 0;
}

.btnNaranjaAcacias a {
  font-size: var(--letra-p);
}
.btnNaranjaAcacias2{
  width: 15rem !important;
}
.btnNaranjaAcaciasContacto {
  width: 100%;
}
.proyecto-acacias h1{

  font-size: var(--letra-h3);

}
.descubre-acacias{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: column;
  width: 100%;

}
.descubre-acacias p{
 
  width: 100%;
  font-size: var(--letra-p);
  padding: 0.8rem;
  

}
.info-y-avance-acacias{
  width: 90%;
  
}
.galerias-fotos-acacias {
  position: relative;
  width: 80%;
  margin: 0 0 1rem;
  
}


.btn-flecha {

  font-size: var(--letra-p);
  width: 2rem;
  height: 2rem;

}

.espacios-acacias{
  
  flex-direction: column;
  height: auto;
  padding: 0;
  margin:0;
}
.espacios-acacias h1{
  font-size: var(--letra-h2);
  margin:0;
  padding: 1rem 0 0 0;
  -webkit-text-stroke: 1px var(--gris-claro); /* Añade un contorno negro */
  width: 100%;
  text-align: center;
}
.espacios-acacias p{
  font-size: var(--letra-h3);
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;
  margin:0;
}
.lineaVerticalNaranja{
  width: 90%;
  height: 0.4rem;
  margin: 1rem;

}

.info-acacias {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
 }
.info-acacias-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:0 1rem 0 1rem;

}
.info-acacias-img img{
  margin:1.5rem;
  height: 3.5rem;
  

}
.info-acacias-texto{
  
  flex-direction: column;

}
.info-acacias-texto p{
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 1rem 0 0 0;
  text-align: left;
  height: 5.5rem;
}

.planta-acacias{

  flex-direction: column;
  padding: 0.5rem;
}

.planta-acacias h1{

  font-size: var(--letra-h3);
  
}

.comodidades-acacias{
  background-image: url('utils/acacias/Banner2.webp');
  background-size: auto 150px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;/* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0.5rem;

}
.comodidades-acacias h1{

  font-size: var(--letra-h3);
  width: 100%;
  letter-spacing: 0.05em;
  margin:1.5rem 0 1rem 0;
  -webkit-text-stroke: 1px var(--gris-oscuro);
  
}

.comodidades-acacias-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);


}
.ubicacion-acacias{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
.ubicacion-acacias-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

}
.ubicacion-acacias-info h1{
  
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.1em;
  margin:0.5rem;
  padding: 0;
  
}
.ubicacion-acacias-info h2{
  font-size: var(--letra-h3);
  letter-spacing: -0.05em;
  margin:0;
  padding: 0;
}
.ubicacion-acacias-info h3{
  font-size: var(--letra-h3);
  letter-spacing: 0.05em;
  margin:0;
  padding: 0;
  width: 100%;
}
.ubicacion-acacias-info img{
  width: 3rem;
}
.ubicacion-acacias-maps{
  width: 90%;
  margin:0.5rem
}
.ubicacion-acacias-maps img{
  width: 100%;

}

.acacias-eleccion h1,h2{
  font-size: var(--letra-h3);
}

.acacias-info{
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
}


.contactenos-acacias{

  flex-direction: column;
  padding: 0.5rem 0 0.5rem 0.5rem;
  
}
.contactenos-acacias-info{
  width: 100%;
  padding: 0.5rem;

}
.contactenos-acacias-info h1{

  font-size: var(--letra-h3);
  letter-spacing: -0.01em;
  -webkit-text-stroke: 1px var(--gris-oscuro); /* Añade un contorno negro */
  width: 100%;
}
.contactenos-acacias-info p{

  font-size: var(--letra-h3);
  width: 100%;
  margin:0.5rem;
}

/*alameda*/


.alameda{

  padding: 0;
  margin: 0;

}

.banner-alameda{

  background-image: url('utils/alameda/Banner.webp');
  background-size: auto 200px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;
  height: auto; /* Ajusta la altura según lo necesites */
  width: 100%; /* Ajusta el ancho si es necesario */
  overflow: hidden; 
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0;
}
.banner-alameda-info{
  display: flex; 
  flex-direction: column;
  align-items:center; 
  justify-content: space-between; 
  padding: 0.5rem;
  width: 100%;
  height: 35rem;
}
.logoAlameda{
  height: 10rem;
  margin: 1rem 0 0 0;
}

.banner-alameda-info h1{
  font-size: var(--letra-h2);
  width: 100%;
  padding: 1rem;
}
.banner-alameda-info h2{
  font-size: var(--letra-h3);



}
.banner-alameda-info h3{
  width: 100%;
  font-size: var(--letra-h3);
  padding: 1rem;
}


.form-alameda {
  border-radius: 2.5rem;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20rem; /* Ancho total de la tarjeta del formulario */
  margin: 0 0 1rem 0;
}

.btnNaranjaAlameda a {
  font-size: var(--letra-p);
}
.btnNaranjaAlameda2{
  width: 15rem !important;
}
.btnNaranjaAlamedaContacto {
  width: 100%;
}
.proyecto-alameda h1{

  font-size: var(--letra-h3);

}
.descubre-alameda{
  display: flex; /* Asegura alineación dentro del botón */
  align-items: center; 
  justify-content: center;
  flex-direction: column;
  width: 100%;

}
.descubre-alameda p{
 
  width: 100%;
  font-size: var(--letra-p);
  padding: 0.8rem;
  

}
.info-y-avance-alameda{
  width: 90%;
  
}
.galerias-fotos-alameda {
  position: relative;
  width: 80%;
  margin: 0 0 1rem;
  
}


.btn-flecha {

  font-size: var(--letra-p);
  width: 2rem;
  height: 2rem;

}

.espacios-alameda{
  
  flex-direction: column;
  height: auto;
  padding: 0;
  margin:0;
}
.espacios-alameda h1{
  font-size: var(--letra-h2);
  margin:0;
  padding: 1rem 0 0 0;
  -webkit-text-stroke: 1px var(--gris-claro); /* Añade un contorno negro */
  width: 100%;
  text-align: center;
}
.espacios-alameda p{
  font-size: var(--letra-h3);
  width: 100%;
  padding: 0 0.5rem 0 0.5rem;
  margin:0;
}
.lineaVerticalAzulClaro{
  width: 90%;
  height: 0.4rem;
  margin: 1rem;

}
.lineaVerticalNaranjaClaro{
  width: 90%;
  height: 0.4rem;
  margin: 1rem;
}

.info-alameda {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: auto;
 }
.info-alameda-img{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 20%;
  margin:1rem;
  margin:0 1rem 0 1rem;

}
.info-alameda-img img{
  margin:1.5rem;
  height: 3.5rem;
  

}
.info-alameda-texto{
  
  flex-direction: column;

}
.info-alameda-texto p{
  font-size: var(--letra-h3);
  padding: 0;
  width: 100%;
  margin: 1rem 0 0 0;
  text-align: left;
  height: 5.5rem;
}

.planta-alameda{

  flex-direction: column;
  padding: 0.5rem;
}

.planta-alameda h1{

  font-size: var(--letra-h3);
  
}

.comodidades-alameda{
  background-image: url('utils/alameda/Banner2.webp');
  background-size: auto 150px; /* Ajusta la altura de la imagen a 300px y la escala proporcionalmente */
  background-position: center top; /* Alinea la imagen hacia arriba */
  background-repeat: no-repeat;/* Evita la repetición de la imagen */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: auto;
  padding: 0.5rem;

}
.comodidades-alameda h1{

  font-size: var(--letra-h3);
  width: 100%;
  letter-spacing: 0.05em;
  margin:1.5rem 0 1rem 0;
  -webkit-text-stroke: 1px var(--azul-oscuro-alameda);
  
}

.comodidades-alameda-lista p{
  width: 100%;
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);


}
.ubicacion-alameda{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0.5rem;
}
.ubicacion-alameda-info{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  width: 100%;

}
.ubicacion-alameda-info h1{
  
  font-size: var(--letra-h3);
  color: var(--gris-oscuro);
  -webkit-text-stroke: 2px var(--gris-oscuro); /* Añade un contorno negro */
  letter-spacing: 0.1em;
  margin:0.5rem;
  padding: 0;
  
}
.ubicacion-alameda-info h2{
  font-size: var(--letra-h3);
  letter-spacing: -0.05em;
  margin:0;
  padding: 0;
}

.ubicacion-alameda-info img{
  width: 3rem;
}
.ubicacion-alameda-maps{
  width: 90%;
  margin:0.5rem
}
.ubicacion-alameda-maps img{
  width: 100%;

}

.alameda-eleccion h1,h2{
  font-size: var(--letra-h3);
}

.alameda-info{
  flex-direction: row;
  width: 100%;
  align-items: flex-start;
  justify-content: center;
 
}
.acceso , .independencia , .ambiente{
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;


}
.acceso img, .independencia img, .ambiente img{
  width: 5rem;
  height: auto;
}
.acceso p, .independencia p, .ambiente p{
  width: 7rem;
  height: auto;
  font-size: var(--letra-pp);
  text-align: center;
  
}


.contactenos-alameda{

  flex-direction: column;
  padding: 0.5rem 0 0.5rem 0.5rem;
  
}
.contactenos-alameda-info{
  width: 100%;
  padding: 0.5rem;

}
.contactenos-alameda-info h1{

  font-size: var(--letra-h3);
  letter-spacing: -0.01em;
  width: 100%;
  -webkit-text-stroke: 1px var(--azul-oscuro-alameda);
}
.contactenos-alameda-info p{

  font-size: var(--letra-h3);
  width: 100%;
  margin:0.5rem;
}

/*Constructor*/
.constructor-page{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: auto;
  width: 100%;
}

.constructor-page-title{
  
  display: flex; 
  flex-direction: column;
  align-items: flex-end; 
  margin:4rem 0 0 0;
  padding: 0;
  width: 20rem;
  height: 4rem;
  position: relative;
  
}

.constructor-page-title img{
  width: 5rem;
  height: auto;
  margin: 0 2rem 0 0;
}
.constructor-page-title h2{
  font-size: var(--letra-h3);
  padding: 0.8rem;
  border-radius: 0 1.5rem 1.5rem 0;
  width: 20rem;
  text-align: right;
}
.constructor-page-title h2 strong {
  font-weight: 900; /* O usa 800 si 900 es demasiado grueso */
  -webkit-text-stroke: 1.5px var(--gris-oscuro); /* Añade un contorno negro */
}


#elementoC1 {
  position: absolute;
  top: -70%;
  transform: translateY(-10%);
  z-index: 3; /* Mayor valor = más arriba */
}

#elementoC2 {
  position: absolute;
  top: 5%;
  transform: translateY(-10%);
  z-index: 2; /* Debajo de elemento1 */
}

.constructor-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;
}
.mensaje-constructor{
  width: 100%;
  padding: 1rem;
}
.mensaje-constructor h2{
  font-size: var(--letra-h2);
  text-align: left;
  -webkit-text-stroke: 2px var(--naranja);
}
.mensaje-constructor p{
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  
}
/*Vivienda*/
.vivienda-page{
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  height: auto;
  width: 100%;
}

.vivienda-page-title{
  
  display: flex; 
  flex-direction: column;
  align-items: flex-end; 
  margin:4rem 0 0 0;
  padding: 0;
  width: 20rem;
  height: 4rem;
  position: relative;
  
}

.vivienda-page-title img{
  width: 4rem;
  height: auto;
  margin: 0 2rem 0 0;
}
.vivienda-page-title h2{
  font-size: var(--letra-h3);
  padding: 0.8rem;
  border-radius: 0 1.5rem 1.5rem 0;
  width: 20rem;
  text-align: right;
}
.vivienda-page-title h2 strong {
  font-weight: 900; /* O usa 800 si 900 es demasiado grueso */
  -webkit-text-stroke: 1.5px var(--gris-oscuro); /* Añade un contorno negro */
}


#elementoV1 {
  position: absolute;
  top: -70%;
  transform: translateY(-10%);
  z-index: 3; /* Mayor valor = más arriba */
}

#elementoV2 {
  position: absolute;
  top: 5%;
  transform: translateY(-10%);
  z-index: 2; /* Debajo de elemento1 */
}

.vivienda-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;
}
.mensaje-vivienda{
  width: 100%;
  padding: 1rem;
}
.mensaje-vivienda h2{
  font-size: var(--letra-h2);
  text-align: left;
  -webkit-text-stroke: 2px var(--naranja);
}
.mensaje-vivienda p{
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  
}

/*PQR*/
.pqr-page{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;
  width: 100%;
  margin: 4rem 0 0 0;
}



.pqr-form{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: justify;
}
.mensaje-pqr{
  width: 100%;
  padding: 0 1rem 1rem 1rem;
}
.mensaje-pqr h1{
  font-size: var(--letra-h2);
  color: var(--naranja);
  font-family: var(--font-titulos);
  text-align: left;
  -webkit-text-stroke: 2.5px var(--naranja);
}
.mensaje-pqr h2{
  font-size: 2rem;
  color: var(--gris-oscuro);
  font-family: var(--font-titulos);
  text-align: left;
  
}
.mensaje-pqr p{
  font-size: var(--letra-p);
  color: var(--gris-oscuro);
  font-family: var(--font-cuerpo);
  
}

/*footbar*/

.footbar {
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: space-between;  
  padding: 1rem;
  height: 17rem;


}
.footbar-info{
  display: flex; 
  flex-direction: column;
  align-items: flex-start; 
  justify-content: space-between; 
  width: 100%;
  margin: 0.5rem 0.5rem 0 0.5rem;
  
}


.instagram, .email, .ubicacion{
  display: flex; 
  flex-direction: row;
  align-items: center; 
  justify-content: center;
  margin:0;
  padding: 0;
  text-align: left;
  
}
.instagram-icon, .email-icon, .ubicacion-icon{
  height: 1.2rem;
  
}
.footbar-info p, .contactanos p{
  font-size: 0.85rem;
  
}
.contactanos{
  display: flex; 
  flex-direction: column;
  align-items: center; 
  justify-content: center;
  text-align: center;
  width: 100%;
  margin-top: 0;
  
  
}
.lineaVerticalFootbar{
  display: none;
}

.btnNaranjaFootbar a{
  font-size: var(--letra-h3) !important;

  
}

}
